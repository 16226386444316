import React, { useRef, useEffect, useState } from "react";
import "./Button.css";
import YouTube from "react-youtube";

export function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
          setIsComponentVisible(false);
      }
  };

  useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
          document.removeEventListener('click', handleClickOutside, true);
      };
  }, []);

  return { ref, isComponentVisible, setIsComponentVisible };
}

function Button(props) {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const { button, videoId, externalLink, isWhite, leftAdjust } = props;
  const opts = {
    height: '210',
    width: '375',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    }};
  return (
    <div ref={ref} onClick={() => {
      if (externalLink) {
        window.open(externalLink, '_newtab');
      } else {
        setIsComponentVisible(true);
      }
    }}>
      {isComponentVisible && 
      <YouTube 
        videoId={videoId}                  // defaults -> ''
        iframeClassName={"youtube-iframe" + (leftAdjust ? leftAdjust : '')}
        title='ResMed'                    // defaults -> ''
        opts={opts}                   // defaults -> {}
      />
}
      <div className="button-1">
        <img className="start-stop" src={ isWhite ? require("../../static/img/startstopwhite.svg") : "/img/startstop.svg" } alt="StartStop" />
      <div className="button-2">{button}</div>
     </div>
    </div>

    
  );
}

export default Button;
