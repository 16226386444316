import React from "react";
import "./Frame198.css";

function Frame198(props) {
  const { learnMoreAboutYourMask, onceYourEquipment } = props;

  return (
    <div className="frame-19">
      <p className="learn-more-about-your-mask sfprodisplay-normal-log-cabin-20px">{learnMoreAboutYourMask}</p>
      <p className="x-your sfprotext-regular-normal-log-cabin-14px">{onceYourEquipment}</p>
    </div>
  );
}

export default Frame198;
