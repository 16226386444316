import React, { useEffect, useState, useRef } from "react";
import Frame198 from "../Frame198";
import Frame227 from "../Frame227";
import Button from "../Button";
import "./X13Expanded1.css";
import { useHistory } from 'react-router-dom'
import './X13Expanded1.css';

function X13Expanded1(props) {
  useEffect(() => window.scrollTo(0, 0), []);

  const history = useHistory();
  const goToAirfitN20 = () => history.replace("/airfitN20");
  const goToAirfitF20 = () => history.replace("/airfitF20");
  const goToQ = () => history.replace("/quattroAir");

  executeScrollLearnMore = () => this.learnMoreRef.current.scrollIntoView();
  this.learnMoreRef = React.createRef();

  executeScrollStarted = () => this.startedRef.current.scrollIntoView();
  this.startedRef = React.createRef();

  executeScrollSleeping = () => this.sleepingRef.current.scrollIntoView();
  this.sleepingRef = React.createRef();

  executeScrollTips = () => this.tipsRef.current.scrollIntoView();
  this.tipsRef = React.createRef();

  this.videoButtonRef= React.createRef();
  const videoIconClick = () => {
    this.videoButtonRef.current.children[0].click();
    this.videoButtonRef.current.scrollIntoView();
  }

  const [readMore, setReadMore] = useState(false);

  useEffect(() => {
  }, [readMore]);

  const changeReadMore = () => {
    setReadMore(!readMore);
  }

  const {
    title,
    inThisIssueWeve,
    inThisIssue,
    learnMoreAboutYourMask,
    gettingStartedWithMyair,
    sleepingWithACpapMachine1,
    tipsForBuildingYo,
    airfitN201,
    airfitN202,
    airtouchN20Outsidetable60591200X800,
    sleepingWithACpapMachine2,
    adaptingToCpapThe,
    read7TipsForAdju,
    readLess,
    article,
    dtc_Maskrefresh2022_2228_Resmed_01_,
    tipsForBuildingConfidence,
    itMayFeelAwkward,
    video,
    mostPeopleWhoUse,
    sleepWellTheMyairTeam,
    sources,
    frame1981Props,
    frame2271Props,
    frame2272Props,
    frame1982Props,
    buttonProps,
  } = props;

  return (
    <div className="container-center-horizontal x13">
      <div className="x1u463u47-expanded-1 screen">
        <div className="overlap-group">
          <img className="rectangle-395" src={require("../../static/img/rectangle-395-2.png")} alt="Rectangle 395" />
          <header className="header">
            <img className="res-med-logo" src="/img/resmed-logo.svg" alt="ResMed logo" />
            <h1 className="title" style={{'textAlign': 'start'}}>{title}</h1>
            <p className="in-this-issue-weve sfprotext-regular-normal-log-cabin-14px">{inThisIssueWeve}</p>
          </header>
          <div className="table-of-contents">
            <div className="in-this-issue">{inThisIssue}</div>
            <p className="table-of-contents-item sfprotext-regular-normal-science-blue-14px" onClick={executeScrollLearnMore}>
              {learnMoreAboutYourMask}
            </p>
            <div className="getting-started-with-my-air sfprotext-regular-normal-science-blue-14px" onClick={executeScrollStarted}>
              {gettingStartedWithMyair}
            </div>
            <p className="table-of-contents-item sfprotext-regular-normal-science-blue-14px" onClick={executeScrollSleeping}>
              {sleepingWithACpapMachine1}
            </p>
            <p className="tips-for-building-yo sfprotext-regular-normal-science-blue-14px" onClick={executeScrollTips}>{tipsForBuildingYo}</p>
          </div>
        </div>
        <div className="frame-250" ref={this.learnMoreRef}>
          <Frame198
            learnMoreAboutYourMask={frame1981Props.learnMoreAboutYourMask}
            onceYourEquipment={frame1981Props.onceYourEquipment}
          />
          <div className="frame-230">
            <div onClick={goToAirfitN20}>
              <Frame227 airfitF201={airfitN201} airfitF202={airfitN202}  />
            </div>
            <div onClick={goToAirfitF20}>
              <Frame227 airfitF201={frame2271Props.airfitF201} airfitF202={frame2271Props.airfitF202} />
            </div>
            <div onClick={goToQ}>
              <Frame227 airfitF201={frame2272Props.airfitF201} airfitF202={frame2272Props.airfitF202} />
            </div>
          </div>
        </div>
        <div className="what-is-cpap" id="what-is-cpap" ref={this.startedRef}>
          <Frame198
            learnMoreAboutYourMask={frame1982Props.learnMoreAboutYourMask}
            onceYourEquipment={frame1982Props.onceYourEquipment}
          />
        </div>
        <div className="adapting-to-cpap" ref={this.sleepingRef}>
          <img
            className="air-touch-n20-outsid"
            src={require("../../static/img/airtouch-n20-outsidetable-6059-1200x800-5b2df79-1@2x-2.png")}
            alt="AirTouch N20-OutsideTable-6059-1200x800-5b2df79"
          />
          <div className="frame-199">
            <div className="frame-200">
              <p className="ing sfprodisplay-normal-log-cabin-20px">{sleepingWithACpapMachine2}</p>
              <p className="adapting-to-cpap-the sfprotext-regular-normal-log-cabin-14px">{adaptingToCpapThe}</p>
              <div className="button" onClick={changeReadMore} style={{'marginBottom': '0px', 'marginTop': '0px'}}>
                <p className="read sfprotext-normal-science-blue-16px">{!readMore ? read7TipsForAdju : readLess}</p>
                <img className="chevron" src={!readMore ? "/img/chevron-3.svg" : "/img/chevron-1.svg"} alt="Chevron" />
              </div>
              {!readMore && 
                <div style={{'marginBottom': '48px'}}></div>
              }
              {readMore && 
                <p className="tip-1-practice-make">
                <span className="see-more-title">Tip 1: Practice makes perfect</span>
                <br />
                <span className="see-more-text">Like anything else you try for the first time, wearing a CPAP mask can feel weird. But if you don’t get
                accustomed to wearing it, you’ll have trouble sleeping with it. Instead of putting it on right before
                you try to sleep, start by wearing the mask and headgear as much as you can before bedtime or around the
                house during the day.</span>
                <br />
                <br />
                <span className="see-more-text">The more you get used to wearing your mask, the sooner you’ll have sweet dreams.</span>
                <br />
                <br />
                <span className="see-more-title">Tip 2: Use your CPAP mask every time you sleep</span>
                <br />
                <span className="see-more-text">This might be the most important part of adjusting to your new sleep apnoea treatment. In medical terms,
                healthcare providers call this &#39;patient compliance&#39;. Don’t use it one night but not the next.
                Make sure you use it every night and for naps, even if it’s a bit uncomfortable at first. Get yourself
                in a routine right away by putting it on as soon as you get finished with another routine, such as
                brushing your teeth.</span>
                <br />
                <br />
                <span className="see-more-title">Tip 3: Make small adjustments to your CPAP mask nightly</span>
                <br />
                <span className="see-more-text">Your mask may need adjustments every night to make it more comfortable. If you wake up with red marks on
                your face, loosen the mask a little. If it falls off your face in the middle of the night, tighten it up.</span>
                <br />
                <br />
                <span className="see-more-text">If you’re making adjustments, make sure you do so lying down and with the machine on. Many masks inflate
                slightly with the air on, which is a function of how they seal. Lying down with the machine on can help
                ensure you’re getting an accurate fit.</span>
                <br />
                <br />
                <span className="see-more-title">Tip 4: Use AutoRamp™ on your CPAP machine</span>
                <br />
                <span className="see-more-text">This is one of the most popular settings on CPAP machines. The AutoRamp setting allows you to adjust to
                air pressure by starting at a very low air pressure setting while you’re trying to fall asleep then
                increasing to full pressure after you’re asleep. This feature helps you fall asleep easily and
                comfortably then delivers the full treatment while you’re asleep.</span>
                <br />
                <br />
                <span className="see-more-title">Tip 5: Use a humidifier if your nose or throat is dry</span>
                <br />
                <span className="see-more-text">Keeping your nose and throat moist is a must when using CPAP treatment. The air pressure can make your
                airways drier than you’re used to, especially if your room is already lacking moisture.</span>
                <br />
                <br />
                <span className="see-more-text">Your AirSense 10 comes with a humidifier and heated tubing to provide additional moisture. If you
                struggle with dryness, consider adjusting the humidity level. Increase or decrease the humidity as
                needed during your treatment to ensure your throat and mouth are more comfortable. For help with
                adjusting the humidity, see the AirSense 10 user guide or contact your equipment provider.</span>
                <br />
                <br />
                <span className="see-more-title">Tip 6: Wear gloves if you’re having trouble keeping your mask on</span>
                <br />
                <span className="see-more-text">If you’re waking up with your CPAP mask off but don’t remember removing it, try wearing gloves while you
                sleep. Gloves help prevent you from pulling off your mask in the middle of the night when you’re unaware
                you’re doing it.</span>
                <br />
                <br />
                <span className="see-more-text">You should be able to stop wearing gloves after a week or so. If you still have issues with taking your
                mask off after a few weeks, speak to your healthcare or equipment provider regarding possible causes for
                this and treatment options.</span>
                <br />
                <br />
                <span className="see-more-title">Tip 7: Get personalised coaching on your phone</span>
                <br />
                <span className="see-more-text">The key to adjusting to CPAP treatment, as with many things in life, is to be positive and have support.
                ResMed’s myAir app includes sleep coaching that you can access right on your phone, ensuring you have
                support. It can help you track your sleep apnoea treatment and provide you with personalised coaching to
                make treatment more comfortable and you more confident. If you haven’t already, download the myAir app and
                set up your account.</span>
              </p>
              }
              {readMore && 
              <div className="button-1-13" onClick={changeReadMore} style={{'marginBottom': '80px'}}>
                <div className="read sfprotext-normal-science-blue-16px">{readLess}</div>
                <img className="chevron-1" src={!readMore ? "/img/chevron-3.svg" : "/img/chevron-1.svg"} alt="Chevron" />
              </div>
              }
            </div>
          </div>
          <div className="frame-219">
            <img className="guide" src="/img/guide.svg" alt="Guide" />
            <div className="article">{article}</div>
          </div>
        </div>
        <div className="video" ref={this.tipsRef}>
          <img
            className="dtc_-mask-refresh202"
            src={require("../../static/img/dtc-maskrefresh2022-2228-resmed-01-0806-1@2x-2.png")}
            alt="DTC_MaskRefresh2022_2228_ResMed_01_0806 1"
          />
          <div className="frame-204">
            <div className="frame-198">
              <div className="ing sfprodisplay-normal-log-cabin-20px">{tipsForBuildingConfidence}</div>
              <p className="it-may-feel-awkward sfprotext-regular-normal-log-cabin-14px">{itMayFeelAwkward}</p>
            </div>
            <div ref={this.videoButtonRef}>
              <Button videoId="7c-f153O1kI" button={buttonProps.button}  />
            </div>
          </div>
          <div className="frame-220" onClick={videoIconClick}>
            <img className="iconsplay_arrow" src="/img/icons-play-arrow.svg" alt="Icons/play_arrow" />
            <div className="video-1">{video}</div>
          </div>
        </div>
        <div className="frame-226-1" style={{'marginBottom': '72px'}}>
          <div className="sign-off-rating">
            <p className="most-people-who-use sfprotext-regular-normal-log-cabin-14px">{mostPeopleWhoUse}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default X13Expanded1;
