import React from "react";
import "./Frame227.css";

function Frame227(props) {
  const { airfitF201, airfitF202 } = props;

  return (
    <div className="frame-22">
      <div className="air-fit-f20 sfprodisplay-normal-log-cabin-20px">{airfitF201}</div>
      <img className="air-fit-f20-1" src={airfitF202} alt="AirFit F20" />
    </div>
  );
}

export default Frame227;
