import React, { useState, useEffect } from "react";
import Button from "../Button";
import IconspersonOutline24px from "../IconspersonOutline24px";
import "./X12Expanded.css";


function X12Expanded(props) {
  useEffect(() => window.scrollTo(0, 0), []);

  executeScrollLearnCPAP = () => this.learnCPAPRef.current.scrollIntoView();
  this.learnCPAPRef = React.createRef();

  executeScrollAdapting = () => this.adaptingRef.current.scrollIntoView();
  this.adaptingRef = React.createRef();

  executeScrollPatient = () => this.patientRef.current.scrollIntoView();
  this.patientRef = React.createRef();

  executeScrollPatient2 = () => this.patient2Ref.current.scrollIntoView();
  this.patient2Ref = React.createRef();

  const [readMore, setReadMore] = useState(false);

  useEffect(() => {
  }, [readMore]);

  const changeReadMore = () => {
    setReadMore(!readMore);
  }

  const [readMore2, setReadMore2] = useState(false);

  useEffect(() => {
  }, [readMore2]);

  const changeReadMore2 = () => {
    setReadMore2(!readMore2);
  }

  const {
    congratulationsYou,
    whileYouWaitForY,
    inThisIssue,
    adaptingToYourCpapMachine,
    patientStoryUsing1,
    adaptingToACpapMachine,
    startingSleepApnoe,
    readMore1,
    readLess2,
    article,
    airtouchN20Outsidetable60591200X8002,
    patientStoryUsing2,
    spanText3,
    spanText5,
    patientStory,
    buttonProps
  } = props;

  return (
    <div className="x12 container-center-horizontal">
      <div className="x1-2-expanded screen">
        <div className="overlap-group-2">
          <img className="rectangle-400" src={require("../../static/img/rectangle-395.png")} alt="Rectangle 395" />
          <header className="header">
            <img className="res-med-logo" src="/img/resmed-logo.svg" alt="ResMed logo" />
            <h1 className="congratulations-you">{congratulationsYou}</h1>
            <p className="while-you-wait-for-y sfprotext-regular-normal-log-cabin-14px">{whileYouWaitForY}</p>
          </header>
          <div className="table-of-contents">
            <div className="in-this-issue">{inThisIssue}</div>
            <p className="x-your sfprotext-regular-normal-science-blue-14px" onClick={executeScrollLearnCPAP}>Learn more about your CPAP equipment</p>
            <p className="x-your sfprotext-regular-normal-science-blue-14px" onClick={executeScrollAdapting}>{adaptingToYourCpapMachine}</p>
            <p className="patient-story-using sfprotext-regular-normal-science-blue-14px" onClick={executeScrollPatient}>{patientStoryUsing1}</p>
            <p className="x-your sfprotext-regular-normal-science-blue-14px" onClick={executeScrollPatient2}>Patient story: Feeling fitter than ever at 50 </p>
          </div>
        </div>
        <div  className="video" ref={this.learnCPAPRef}>
          <div class="align-items-start">
            <p className="learn-more-about-your-cpap-machine sfprodisplay-normal-log-cabin-20px  break-word-flex">
              Learn more about your CPAP equipment
            </p>
            <p style={{'display': 'flex', 'flexDirection': 'column', 'gap': '8px'}}>
              <span className="see-more-text-bold ">Your CPAP Mask</span>
              <span className="see-more-text break-word-flex">CPAP masks come in different styles to fit unique sleep needs. Your questionnaire answers helped us select the most suitable mask for you. You'll receive that mask in your CPAP equipment delivery. We'll provide information about fitting, cleaning and managing leaks for your mask soon.</span>
            </p>
            <div style={{'display': 'flex', 'flexDirection': 'column', 'gap': '16px'}}>
              <div className="see-more-text-bold ">Your CPAP device: the AirSense™ 10</div>        
              <div style={{'display': 'flex', 'justifyContent': 'center'}}>
                <img
                  className="dtc_-mask-refresh2"
                  src="/img/dtc-maskrefresh2022-2228-resmed-01-0806-1.svg"
                  alt="DTC_MaskRefresh2022_2228_ResMed_01_0806 1"
                />
              </div>
              <div className="see-more-text break-word-flex">Watch this video for information about the AirSense 10 and how to set it up. </div>
            </div>
            <Button button={buttonProps.button} videoId={'xjdmWmSNFPo'} leftAdjust="-left0"/>
          </div>


        </div>

        <div className="x1-2-expanded-item">
          <img
            className="air-touch-n20-outsid"
            src={require("../../static/img/airtouch-n20-outsidetable-6059-1200x800-5b2df79@2x-2.png")}
            alt="AirTouch N20-OutsideTable-6059-1200x800-5b2df79"
          />
          <div className="frame-199"  ref={this.adaptingRef}>
            <div className="frame-200">
              <p className="adapting-to-a-cpap-machine sfprodisplay-normal-log-cabin-20px">{adaptingToACpapMachine}</p>
              <p className="starting-sleep-apnoe sfprotext-regular-normal-log-cabin-14px">{startingSleepApnoe}</p>
              <div className="button" onClick={changeReadMore}  style={{ 'justifyContent': 'start', 'marginTop': '0px'}}>
                <div className="read-less sfprotext-normal-science-blue-16px">{!readMore ? readMore1 : readLess2 }</div>
                <img className="chevron" src={!readMore ? "/img/chevron-3.svg" : "/img/chevron-1.svg"} alt="Chevron" />
              </div>
            </div>
            {readMore &&
              <p className="set-up-your-cpap-equ">
              <span className="see-more-title">Set up your CPAP equipment before bedtime</span>
              <br />
              <span className="see-more-text">When you’re tired at the end of the day and ready to go to sleep, adding an extra step to your routine can
              be a hassle. Instead of waiting until you are ready for bed to set up your CPAP equipment, have everything
              ready to go:</span>
              <br />
              <ul className="see-more-text" style={{'listStyle': 'decimal'}}>
                <li style={{'listStyle': 'decimal', 'display': 'list-item'}}>
                Keep your mask, tubing and machine at your bedside. If you have a humidifier, have water ready as well.
                </li>
                <li style={{'listStyle': 'decimal', 'display': 'list-item'}}>
                Store your cleaning supplies where you’ll clean your mask and humidifier to simplify your routine.
                </li>
                <li style={{'listStyle': 'decimal', 'display': 'list-item'}}>
                Clean your equipment in the morning so it’s clean, dry and ready to use.
                </li>
              </ul>
              <br />
              <br />
              <span className="see-more-title">Wear your CPAP mask throughout the day</span>
              <br />
              <span className="see-more-text">It can be hard to fall asleep wearing a CPAP mask, especially if you’re not used to wearing it.</span>
              <br />
              <br />
              <span className="see-more-text">To get used to the mask without the pressure to fall asleep, try this routine during the day:</span>
              <br />
              <ul className="see-more-text" style={{'listStyle': 'decimal'}}>
                <li style={{'listStyle': 'decimal', 'display': 'list-item'}}>Hold the mask in front of your face and breathe normally for 1 minute.</li>
                <li style={{'listStyle': 'decimal', 'display': 'list-item'}}>Connect the mask to your machine, turn the air on and hold it in front of your face for 1 to 2 minutes,
                  breathing normally.
                  <ol><li  style={{'list-style': 'lower-latin', 'margin-left': '16px'}}>If you find the air pressure uncomfortable, remove the mask from your face for a few minutes then try
                  again.</li></ol>
                </li>
                <li style={{'listStyle': 'decimal', 'display': 'list-item'}}>Practice breathing through the mask and air pressure for 2 minutes at a time.</li>
                <li style={{'listStyle': 'decimal', 'display': 'list-item'}}>Build up to wearing your mask for longer periods of time. Aim to have it on for 30 minutes at a time.</li>
              </ul>              
              <br />
              <br />
              <span className="see-more-title">Sleep with your CPAP machine in stages</span>
              <br />
              <span className="see-more-text">Once you’re comfortable wearing your mask before bed, try to use your equipment while sleeping. Remember,
              it’s okay to start slow.</span>
              <br />
              <br />
              <span className="see-more-text">Try these tips to help ease in to using CPAP treatment to sleep:</span>
              <br />
              <ul style={{'listStyle': 'decimal'}}className="see-more-text">
                <li style={{'listStyle': 'decimal', 'display': 'list-item'}}>Try sleeping with your CPAP machine during short naps, approximately 30-60 minutes.</li>
                <li style={{'listStyle': 'decimal', 'display': 'list-item'}}>Aim to add 30 more minutes of use each time.</li>
                <li style={{'listStyle': 'decimal', 'display': 'list-item'}}>Keep a diary of your efforts and challenges to track your progress.</li>
              </ul>              
              <br />
              <br />
              <span className="see-more-title">Give yourself time to get used to CPAP treatment</span>
              <br />
              <span className="see-more-text">Remember that it’s okay to give yourself time to get used to treatment. Adapting often takes patience and
              perseverance. Your body needs time to adjust and may not feel better right away, but it’s important to not
              give up. If you ever feel discomfort or that something’s not right, talk to your healthcare or equipment
              provider right away.</span>
            </p>
            }
            {readMore &&
            <div className="button" onClick={changeReadMore} style={{ 'justifyContent': 'start', 'marginTop': '0px'}}>
              <div className="read-less sfprotext-normal-science-blue-16px">{!readMore ? readMore1 : readLess2 }</div>
              <img className="chevron" src={!readMore ? "/img/chevron-3.svg" : "/img/chevron-1.svg"} alt="Chevron" />
            </div>
            }
          </div>
          <div className="frame-219-2">
            <img className="guide" src="/img/guide.svg" alt="Guide" />
            <div className="article">{article}</div>
          </div>
        </div>
        <div className="x1-2-expanded-item"  ref={this.patientRef}>
          <img
            className="air-touch-n20-outsid-1"
            src={airtouchN20Outsidetable60591200X8002}
            alt="AirTouch N20-OutsideTable-6059-1200x800-5b2df79"
          />
          <div className="frame-199-1">
            <div className="frame">
              <p className="patient-story-using-1 sfprodisplay-normal-log-cabin-20px">{patientStoryUsing2}</p>
              <p className="res-meds-patient-eng">
                <span className="sfprotext-regular-normal-log-cabin-14px">{spanText3}</span>
                <br/>
                <span className="sfprotext-regular-normal-log-cabin-14px">{spanText5}</span>
              </p>
              <Button videoId="WAA53xoPodg" button={buttonProps.button}/>
            </div>
          </div>
          <div className="frame-219-1">
            <IconspersonOutline24px />
            <div className="patient-story">{patientStory}</div>
          </div>
        </div>
        {/* Create your own */}
        <div className="x1-2-expanded-item"  ref={this.patient2Ref}>
          <img
            className="air-touch-n20-outsid-1"
            src={require('../../static/img/AirTouch N20-OutsideTable-6059-1200x800-5b2df79.png')}
            alt="AirTouch N20-OutsideTable-6059-1200x800-5b2df79"
          />
          <div className="frame-199-1">
            <div className="frame">
              <p className="patient-story-using-1 sfprodisplay-normal-log-cabin-20px">Patient story: Feeling fitter than ever at 50</p>
              <p className="res-meds-patient-eng">
                <span className="sfprotext-regular-normal-log-cabin-14px">David is 49 years old and was diagnosed with obstructive sleep apnoea at 46. His snoring used to be an issue with his family and friends, keeping them awake at home and while travelling. Since his diagnosis, David has not only slept quietly, but his entire wellbeing has improved. </span>
              </p>

              <div className="button" onClick={changeReadMore2} style={{ 'justifyContent': 'start', 'marginTop': '0px'}}>
                <div className="read-less sfprotext-normal-science-blue-16px">{!readMore2 ? "Read more about David’s journey" : readLess2 }</div>
                <img className="chevron" src={!readMore2 ? "/img/chevron-3.svg" : "/img/chevron-1.svg"} alt="Chevron" />
              </div>
              {!readMore2 &&
                <div style={{'marginBottom': '42px'}}></div>
              }

              {readMore2 && 
                <p>
                  <span className="see-more-text">David is 49 and lives with his wife and 2 teenage daughters. David works in London in a senior directorial role for a large company and travels frequently.</span>
                  <br/>
                  <br/>
                  <span className="see-more-title">Snoring as a main symptom</span>
                  <br/>
                  <span className="see-more-text">At 46 years old, David found his snoring had become progressively worse. To a point where it was having a serious effect on his relationship with his wife, who was starting to sleep in the spare room every night.</span>
                  <br />
                  <br/>
                  <span className="see-more-text">David said, 'The final straw came after a boys’ skiing trip after which my friend phoned my wife and asked, “how do you cope with this”?! I knew at that point I needed to do something about my snoring'.  </span>
                  <br/>
                  <br/>
                  <span className="see-more-title">Diagnosis</span>
                  <br/>
                  <span className="see-more-text">During a 2 yearly medical, David discussed his snoring with the physician who recommended a sleep study be performed. This showed that David was suffering from obstructive sleep apnoea (OSA). ‘I remember thinking, “oh my god! Does this mean surgery”? But I was quickly assured there is a non-invasive route to treatment, and I was referred to ResMed’s Centre for Healthy Sleep in London for CPAP treatment’.</span>
                  <br/>
                  <br/>
                  <span className="see-more-title">Sleep apnoea treatment</span>
                  <br/>
                  <span className="see-more-text">David returned home that night with his new therapy machine and mask, amidst many questions from the family, but also some excitement that it might be as good as they’d been promised. David recalls that on camping holidays and when sharing family rooms, the whole family had been affected by his snoring, so it wasn’t just him holding his breath.</span>
                  <br/>
                  <br/>
                  <span className="see-more-text">‘The first few nights were definitely a little tricky and it took a couple of weeks to settle in – Verity [a clinician at ResMed’s Centre for Healthy Sleep] made the point that I was trying to unpick decades of sleeping habits. But – with her at the end of the phone and responding to emails with recommendations, it didn’t take long before I simply settled in and started feeling the full benefits of my therapy’.</span>
                  <br/>
                  <br/>
                  <span className="see-more-title">Travelling with CPAP equipment</span>
                  <br/>
                  <span className="see-more-text">Very early on in David’s treatment, he went on another skiing trip with his friends. ‘I remember they had such bad experiences in the past that they drew straws to share a room with me, but I was happy to unveil my new equipment and after the customary Darth Vader jokes, it was quickly evident that I had completely turned a corner and we all slept well’.</span>
                  <br/>
                  <br/>
                  <span className="see-more-title">OSA can affect anyone</span>
                  <br/>
                  <span className="see-more-text">David has always kept fit and maintained a collar size of 15.5, so he didn’t automatically seem like a candidate for OSA. Even then, he has noticed a huge difference in ‘wellness’ since his diagnosis and treatment for OSA. ‘As I approach 50, I feel fitter than I have ever felt before and am able to run 5k in 19 minutes, which I could never do prior to my diagnosis’.</span>
                  <br/>
                  <br/>
                  <span className="see-more-title">Accept CPAP treatment, don’t fight it</span>
                  <br/>
                  <span className="see-more-text">David concludes ‘Prior to my diagnosis with OSA, relationships, friendships, travel, work, everything in my life was being eroded. Had it not been fixed, I can’t imagine where I’d be now. To anybody suffering from snoring, I’d advise them to get it checked out, and don’t just stick their head in the sand. To anybody recently diagnosed, I’d say “accept this therapy because it works – embrace it, don’t fight it”’.</span>
                </p>
              }

              { readMore2 &&
                <div className="button" onClick={changeReadMore2}  style={{ 'justifyContent': 'start', 'marginTop': '0px'}}>
                  <div className="read-less sfprotext-normal-science-blue-16px">{!readMore2 ? "Read more about David’s journey" : readLess2 }</div>
                  <img className="chevron" src={!readMore2 ? "/img/chevron-3.svg" : "/img/chevron-1.svg"} alt="Chevron" />
                </div>
              }



            </div>
          </div>
          <div className="frame-219-1">
            <IconspersonOutline24px />
            <div className="patient-story">{patientStory}</div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default X12Expanded;
