import React, { useEffect, useState, useRef } from "react";
import Frame198 from "../Frame198";
import IconspersonOutline24px from "../IconspersonOutline24px";
import Button from "../Button";
import "./X13Expanded1.css";
import './X13Expanded1.css';

function X13Expanded1(props) {
  useEffect(() => window.scrollTo(0, 0), []);

  executeScrollLearnMore = () => this.learnMoreRef.current.scrollIntoView();
  this.learnMoreRef = React.createRef();

  executeScrollStarted = () => this.startedRef.current.scrollIntoView();
  this.startedRef = React.createRef();

  executeScrollSleeping = () => this.sleepingRef.current.scrollIntoView();
  this.sleepingRef = React.createRef();

  executeScrollTips = () => this.tipsRef.current.scrollIntoView();
  this.tipsRef = React.createRef();

  executeScrollPatient2 = () => this.patient2Ref.current.scrollIntoView();
  this.patient2Ref = React.createRef();

  this.videoButtonRef= React.createRef();
  const videoIconClick = () => {
    this.videoButtonRef.current.children[0].click();
    this.videoButtonRef.current.scrollIntoView();
  }

  const [readMore, setReadMore] = useState(false);

  useEffect(() => {
  }, [readMore]);

  const changeReadMore = () => {
    setReadMore(!readMore);
  }

  const [readMore2, setReadMore2] = useState(false);

  useEffect(() => {
  }, [readMore2]);

  const changeReadMore2 = () => {
    setReadMore2(!readMore2);
  }

  const {
    title,
    inThisIssueWeve,
    inThisIssue,
    gettingStartedWithMyair,
    sleepingWithACpapMachine1,
    tipsForBuildingYo,
    sleepingWithACpapMachine2,
    adaptingToCpapThe,
    read7TipsForAdju,
    readLess,
    readLess2,
    article,
    tipsForBuildingConfidence,
    itMayFeelAwkward,
    video,
    mostPeopleWhoUse,
    frame1982Props,
    buttonProps,
    patientStory
  } = props;

  return (
    <div className="container-center-horizontal x13">
      <div className="x1u463u47-expanded-1 screen">
        <div className="overlap-group">
          <img className="rectangle-395" src={require("../../static/img/rectangle-395-2.png")} alt="Rectangle 395" />
          <header className="header">
            <img className="res-med-logo" src="/img/resmed-logo.svg" alt="ResMed logo" />
            <h1 className="title" style={{'textAlign': 'start'}}>{title}</h1>
            <p className="in-this-issue-weve sfprotext-regular-normal-log-cabin-14px">{inThisIssueWeve}</p>
          </header>
          <div className="table-of-contents">
            <div className="in-this-issue">{inThisIssue}</div>
            <div className="getting-started-with-my-air sfprotext-regular-normal-science-blue-14px" onClick={executeScrollStarted}>
              {gettingStartedWithMyair}
            </div>
            <p className="table-of-contents-item sfprotext-regular-normal-science-blue-14px" onClick={executeScrollSleeping}>
              {sleepingWithACpapMachine1}
            </p>
            <p className="tips-for-building-yo sfprotext-regular-normal-science-blue-14px" onClick={executeScrollTips}>{tipsForBuildingYo}</p>
            <p className="x-your sfprotext-regular-normal-science-blue-14px" onClick={executeScrollPatient2}>Patient story: Feeling fitter than ever at 50 </p>
          </div>
        </div>
        <div className="what-is-cpap" id="what-is-cpap" ref={this.startedRef}>
          <Frame198
            learnMoreAboutYourMask={frame1982Props.learnMoreAboutYourMask}
            onceYourEquipment={frame1982Props.onceYourEquipment}
          />
        </div>
        <div className="adapting-to-cpap" ref={this.sleepingRef}>
          <img
            className="air-touch-n20-outsid"
            src={require("../../static/img/airtouch-n20-outsidetable-6059-1200x800-5b2df79-1@2x-2.png")}
            alt="AirTouch N20-OutsideTable-6059-1200x800-5b2df79"
          />
          <div className="frame-199">
            <div className="frame-200">
              <p className="ing sfprodisplay-normal-log-cabin-20px">{sleepingWithACpapMachine2}</p>
              <p className="adapting-to-cpap-the sfprotext-regular-normal-log-cabin-14px">{adaptingToCpapThe}</p>
              <div className="button" onClick={changeReadMore} style={{'marginBottom': '0px', 'marginTop': '0px'}}>
                <p className="read sfprotext-normal-science-blue-16px">{!readMore ? read7TipsForAdju : readLess}</p>
                <img className="chevron" src={!readMore ? "/img/chevron-3.svg" : "/img/chevron-1.svg"} alt="Chevron" />
              </div>
              {!readMore && 
                <div style={{'marginBottom': '48px'}}></div>
              }
              {readMore && 
                <p className="tip-1-practice-make">
                <span className="see-more-title">Tip 1: Practice makes perfect</span>
                <br />
                <span className="see-more-text">Like anything else you try for the first time, wearing a CPAP mask can feel weird. But if you don’t get
                accustomed to wearing it, you’ll have trouble sleeping with it. Instead of putting it on right before
                you try to sleep, start by wearing the mask and headgear as much as you can before bedtime or around the
                house during the day.</span>
                <br />
                <br />
                <span className="see-more-text">The more you get used to wearing your mask, the sooner you’ll have sweet dreams.</span>
                <br />
                <br />
                <span className="see-more-title">Tip 2: Use your CPAP mask every time you sleep</span>
                <br />
                <span className="see-more-text">This might be the most important part of adjusting to your new sleep apnoea treatment. In medical terms,
                healthcare providers call this &#39;patient compliance&#39;. Don’t use it one night but not the next.
                Make sure you use it every night and for naps, even if it’s a bit uncomfortable at first. Get yourself
                in a routine right away by putting it on as soon as you get finished with another routine, such as
                brushing your teeth.</span>
                <br />
                <br />
                <span className="see-more-title">Tip 3: Make small adjustments to your CPAP mask nightly</span>
                <br />
                <span className="see-more-text">Your mask may need adjustments every night to make it more comfortable. If you wake up with red marks on
                your face, loosen the mask a little. If it falls off your face in the middle of the night, tighten it up.</span>
                <br />
                <br />
                <span className="see-more-text">If you’re making adjustments, make sure you do so lying down and with the machine on. Many masks inflate
                slightly with the air on, which is a function of how they seal. Lying down with the machine on can help
                ensure you’re getting an accurate fit.</span>
                <br />
                <br />
                <span className="see-more-title">Tip 4: Use AutoRamp™ on your CPAP machine</span>
                <br />
                <span className="see-more-text">This is one of the most popular settings on CPAP machines. The AutoRamp setting allows you to adjust to
                air pressure by starting at a very low air pressure setting while you’re trying to fall asleep then
                increasing to full pressure after you’re asleep. This feature helps you fall asleep easily and
                comfortably then delivers the full treatment while you’re asleep.</span>
                <br />
                <br />
                <span className="see-more-title">Tip 5: Use a humidifier if your nose or throat is dry</span>
                <br />
                <span className="see-more-text">Keeping your nose and throat moist is a must when using CPAP treatment. The air pressure can make your
                airways drier than you’re used to, especially if your room is already lacking moisture.</span>
                <br />
                <br />
                <span className="see-more-text">Your AirSense 10 comes with a humidifier and heated tubing to provide additional moisture. If you
                struggle with dryness, consider adjusting the humidity level. Increase or decrease the humidity as
                needed during your treatment to ensure your throat and mouth are more comfortable. For help with
                adjusting the humidity, see the AirSense 10 user guide or contact your equipment provider.</span>
                <br />
                <br />
                <span className="see-more-title">Tip 6: Wear gloves if you’re having trouble keeping your mask on</span>
                <br />
                <span className="see-more-text">If you’re waking up with your CPAP mask off but don’t remember removing it, try wearing gloves while you
                sleep. Gloves help prevent you from pulling off your mask in the middle of the night when you’re unaware
                you’re doing it.</span>
                <br />
                <br />
                <span className="see-more-text">You should be able to stop wearing gloves after a week or so. If you still have issues with taking your
                mask off after a few weeks, speak to your healthcare or equipment provider regarding possible causes for
                this and treatment options.</span>
                <br />
                <br />
                <span className="see-more-title">Tip 7: Get personalised coaching on your phone</span>
                <br />
                <span className="see-more-text">The key to adjusting to CPAP treatment, as with many things in life, is to be positive and have support.
                ResMed’s myAir app includes sleep coaching that you can access right on your phone, ensuring you have
                support. It can help you track your sleep apnoea treatment and provide you with personalised coaching to
                make treatment more comfortable and you more confident. If you haven’t already, download the myAir app and
                set up your account.</span>
              </p>
              }
              {readMore && 
              <div className="button-1-13" onClick={changeReadMore} style={{'marginBottom': '80px'}}>
                <div className="read sfprotext-normal-science-blue-16px">{readLess}</div>
                <img className="chevron-1" src={!readMore ? "/img/chevron-3.svg" : "/img/chevron-1.svg"} alt="Chevron" />
              </div>
              }
            </div>
          </div>
          <div className="frame-219">
            <img className="guide" src="/img/guide.svg" alt="Guide" />
            <div className="article">{article}</div>
          </div>
        </div>
        <div className="video" style={{ 'paddingBottom': '48px'}}>
          <img
            className="dtc_-mask-refresh202"
            src={require("../../static/img/dtc-maskrefresh2022-2228-resmed-01-0806-1@2x-2.png")}
            alt="DTC_MaskRefresh2022_2228_ResMed_01_0806 1"
          />
          <div className="frame-204" ref={this.tipsRef}>
            <div className="frame-198">
              <div className="ing sfprodisplay-normal-log-cabin-20px">{tipsForBuildingConfidence}</div>
              <p className="it-may-feel-awkward sfprotext-regular-normal-log-cabin-14px">{itMayFeelAwkward}</p>
            </div>
            <div ref={this.videoButtonRef}>
              <Button videoId="7c-f153O1kI" button={buttonProps.button}  />
            </div>
          </div>
          <div className="frame-220" onClick={videoIconClick}>
            <img className="iconsplay_arrow" src="/img/icons-play-arrow.svg" alt="Icons/play_arrow" />
            <div className="video-1">{video}</div>
          </div>
        </div>
        {/* Create your own */}
        <div className="x1-2-expanded-item"  ref={this.patient2Ref}>
          <img
            className="air-touch-n20-outsid-1"
            src={require('../../static/img/AirTouch N20-OutsideTable-6059-1200x800-5b2df79.png')}
            alt="AirTouch N20-OutsideTable-6059-1200x800-5b2df79"
          />
          <div className="frame-199-1">
            <div className="frame">
              <p className="patient-story-using-1 sfprodisplay-normal-log-cabin-20px">Patient story: Feeling fitter than ever at 50</p>
              <p className="res-meds-patient-eng">
                <span className="sfprotext-regular-normal-log-cabin-14px">David is 49 years old and was diagnosed with obstructive sleep apnoea at 46. His snoring used to be an issue with his family and friends, keeping them awake at home and while travelling. Since his diagnosis, David has not only slept quietly, but his entire wellbeing has improved. </span>
              </p>

              <div className="button" onClick={changeReadMore2} style={{ 'justifyContent': 'start', 'marginTop': '0px'}}>
                <div className="read-less sfprotext-normal-science-blue-16px">{!readMore2 ? "Read more about David’s journey" : readLess2 }</div>
                <img className="chevron" src={!readMore2 ? "/img/chevron-3.svg" : "/img/chevron-1.svg"} alt="Chevron" />
              </div>

              {readMore2 && 
                <p>
                  <span className="see-more-text">David is 49 and lives with his wife and 2 teenage daughters. David works in London in a senior directorial role for a large company and travels frequently.</span>
                  <br/>
                  <br/>
                  <span className="see-more-title">Snoring as a main symptom</span>
                  <br/>
                  <span className="see-more-text">At 46 years old, David found his snoring had become progressively worse. To a point where it was having a serious effect on his relationship with his wife, who was starting to sleep in the spare room every night.</span>
                  <br />
                  <br/>
                  <span className="see-more-text">David said, 'The final straw came after a boys’ skiing trip after which my friend phoned my wife and asked, “how do you cope with this”?! I knew at that point I needed to do something about my snoring'.  </span>
                  <br/>
                  <br/>
                  <span className="see-more-title">Diagnosis</span>
                  <br/>
                  <span className="see-more-text">During a 2 yearly medical, David discussed his snoring with the physician who recommended a sleep study be performed. This showed that David was suffering from obstructive sleep apnoea (OSA). ‘I remember thinking, “oh my god! Does this mean surgery”? But I was quickly assured there is a non-invasive route to treatment, and I was referred to ResMed’s Centre for Healthy Sleep in London for CPAP treatment’.</span>
                  <br/>
                  <br/>
                  <span className="see-more-title">Sleep apnoea treatment</span>
                  <br/>
                  <span className="see-more-text">David returned home that night with his new therapy machine and mask, amidst many questions from the family, but also some excitement that it might be as good as they’d been promised. David recalls that on camping holidays and when sharing family rooms, the whole family had been affected by his snoring, so it wasn’t just him holding his breath.</span>
                  <br/>
                  <br/>
                  <span className="see-more-text">‘The first few nights were definitely a little tricky and it took a couple of weeks to settle in – Verity [a clinician at ResMed’s Centre for Healthy Sleep] made the point that I was trying to unpick decades of sleeping habits. But – with her at the end of the phone and responding to emails with recommendations, it didn’t take long before I simply settled in and started feeling the full benefits of my therapy’.</span>
                  <br/>
                  <br/>
                  <span className="see-more-title">Travelling with CPAP equipment</span>
                  <br/>
                  <span className="see-more-text">Very early on in David’s treatment, he went on another skiing trip with his friends. ‘I remember they had such bad experiences in the past that they drew straws to share a room with me, but I was happy to unveil my new equipment and after the customary Darth Vader jokes, it was quickly evident that I had completely turned a corner and we all slept well’.</span>
                  <br/>
                  <br/>
                  <span className="see-more-title">OSA can affect anyone</span>
                  <br/>
                  <span className="see-more-text">David has always kept fit and maintained a collar size of 15.5, so he didn’t automatically seem like a candidate for OSA. Even then, he has noticed a huge difference in ‘wellness’ since his diagnosis and treatment for OSA. ‘As I approach 50, I feel fitter than I have ever felt before and am able to run 5k in 19 minutes, which I could never do prior to my diagnosis’.</span>
                  <br/>
                  <br/>
                  <span className="see-more-title">Accept CPAP treatment, don’t fight it</span>
                  <br/>
                  <span className="see-more-text">David concludes ‘Prior to my diagnosis with OSA, relationships, friendships, travel, work, everything in my life was being eroded. Had it not been fixed, I can’t imagine where I’d be now. To anybody suffering from snoring, I’d advise them to get it checked out, and don’t just stick their head in the sand. To anybody recently diagnosed, I’d say “accept this therapy because it works – embrace it, don’t fight it”’.</span>
                </p>
              }

              { readMore2 &&
                <div className="button" onClick={changeReadMore2}  style={{ 'justifyContent': 'start', 'marginTop': '0px'}}>
                  <div className="read-less sfprotext-normal-science-blue-16px">{!readMore2 ? "Read more about David’s journey" : readLess2 }</div>
                  <img className="chevron" src={!readMore2 ? "/img/chevron-3.svg" : "/img/chevron-1.svg"} alt="Chevron" />
                </div>
              }



            </div>
          </div>
          <div className="frame-219-1">
            <IconspersonOutline24px />
            <div className="patient-story">{patientStory}</div>
          </div>
        </div>
        <div className="frame-226-1" style={{'marginBottom': '62px', 'marginTop': '0px'}}>
          <div className="sign-off-rating">
            <p className="most-people-who-use sfprotext-regular-normal-log-cabin-14px">{mostPeopleWhoUse}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default X13Expanded1;
