import React from "react";
import Button from "../Button";
import Button2 from "../Button2";
import "./Actions.css";

function Actions(props) {
  const { button, externalLink, videoId, isWhite, leftAdjust } = props;
  return (
    <div className="actions">
      <Button isWhite={isWhite} button={button} externalLink={externalLink} videoId={videoId} leftAdjust={leftAdjust} />
      <Button2 />
    </div>
  );
}

export default Actions;
