import React from "react";
import Text from "../Text";
import Actions from "../Actions";
import "./GradientCard.css";

function GradientCard(props) {
  const { className, textProps, button, externalLink, videoId, leftAdjust } = props;

  return (
    <div className={`gradient-card ${className || "gradient-card-0"}`}>
      <Text>{textProps.children}</Text>
      <Actions isWhite={true} button={button} externalLink={externalLink} videoId={videoId} leftAdjust={leftAdjust} />
    </div>
  );
}

export default GradientCard;
