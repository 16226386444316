import React from "react";
import Button from "../Button";
import IconspersonOutline24px from "../IconspersonOutline24px";
import Frame232 from "../Frame232";
import "./X11Expanded.css";
import React, { useEffect } from "react";
import { useState } from "react";

function X11Expanded(props) {
  executeScrollWhatIs = () => this.whatIsRef.current.scrollIntoView();
  this.whatIsRef = this.whatIsRef ? this.whatIsRef : React.createRef();

  executeScrollWhatTo = () => this.whatToRef.current.scrollIntoView();
  this.whatToRef = this.whatToRef ? this.whatToRef : React.createRef();

  executeScrollPatientStory = () => this.patientStoryRef.current.scrollIntoView();
  this.patientStoryRef = this.patientStoryRef ? this.patientStoryRef : React.createRef();

  executeScrollAirconnect = () => this.airconnectRef.current.scrollIntoView();
  this.airconnectRef = this.airconnectRef ? this.airconnectRef : React.createRef();

  const [readMoreOSA, setReadMoreOSA] = useState(false);

  useEffect(() => {
  }, [readMoreOSA]);

  const changeReadMoreOSA = () => {
    setReadMoreOSA(!readMoreOSA);
  }

  this.videoButtonRef= React.createRef();
  const videoIconClick = () => {
    this.videoButtonRef.current.children[0].click();
    this.videoButtonRef.current.scrollIntoView();
  }

  const {
    title,
    wellBeSendingYou,
    inThisIssue,
    whatIsCpap1,
    whatToExpectOnCpapTreatment1,
    patientStoryFrom1,
    airconnectFaqs1,
    whatIsCpap2,
    spanText1,
    spanText2,
    spanText3,
    spanText4,
    spanText5,
    dtc_Maskrefresh2022_2228_Resmed_01_,
    whatToExpectOnCpapTreatment2,
    itTookSomeTimeFo,
    video,
    airtouchN20Outsidetable60591200X800,
    patientStoryFrom2,
    name,
    textButton1,
    textButton3,
    patientStory,
    airconnectFaqs2,
    sources,
    buttonProps,
    frame2321Props,
    frame2322Props,
    frame2323Props,
    frame2324Props,
    frame2325Props,
    frame2326Props,
    frame2327Props,
  } = props;

  return (
    <div className="x1 container-center-horizontal">
      <div className="x1-1-expanded screen">
        <div className="overlap-group">
          <img className="rectangle-395" src={require("../../static/img/rectangle-395-0.png")} alt="Rectangle 395" />
          <div className="frame-224">
            <div className="frame-211">
              <img className="res-med-logo" src="/img/resmed-logo.svg" alt="ResMed logo" />
              <h1 className="title">{title}</h1>
              <p className="well-be-sending-you sfprotext-regular-normal-log-cabin-14px">{wellBeSendingYou}</p>
            </div>
            <div className="frame-212">
              <div className="in-this-issue">{inThisIssue}</div>
              <div className="frame-212-item sfprotext-regular-normal-science-blue-14px" onClick={executeScrollWhatIs}>{whatIsCpap1}</div>
              <p className="frame-212-item sfprotext-regular-normal-science-blue-14px" onClick={executeScrollWhatTo}>
                {whatToExpectOnCpapTreatment1}
              </p>
              <p className="frame-212-item sfprotext-regular-normal-science-blue-14px" onClick={executeScrollPatientStory}>{patientStoryFrom1}</p>
              <div className="frame-212-item sfprotext-regular-normal-science-blue-14px" onClick={executeScrollAirconnect}>{airconnectFaqs1}</div>
            </div>
            <div className="frame-203">
              <div className="frame">
                <div className="what sfprodisplay-normal-log-cabin-20px" ref={this.whatIsRef}>{whatIsCpap2}</div>
                <p className="new-patients-often-s sfprotext-regular-normal-log-cabin-14px">
                  <span className="sfprotext-regular-normal-log-cabin-14px">{spanText1}</span>
                  <span className="see-more-text-bold">{spanText2}</span>
                  <span className="sfprotext-regular-normal-log-cabin-14px">{spanText3}</span>
                  <span className="sfprotext-regular-normal-log-cabin-14px">{spanText4}</span>
                  <span className="sfprotext-regular-normal-log-cabin-14px">{spanText5}</span>
                </p>
              </div>
            </div>
            <div className="frame-222">
              <img ref={this.whatToRef}
                className="dtc_-mask-refresh202"
                src={dtc_Maskrefresh2022_2228_Resmed_01_}
                alt="DTC_MaskRefresh2022_2228_ResMed_01_0806 1"
              />
              <div className="frame-1">
                <div className="frame">
                  <p className="what sfprodisplay-normal-log-cabin-20px">{whatToExpectOnCpapTreatment2}</p>
                  <p className="it-took-some-time-fo sfprotext-regular-normal-log-cabin-14px">{itTookSomeTimeFo}</p>
                </div>
                <div ref={this.videoButtonRef}>
                  <Button button={buttonProps.button} videoId={'t6ejb6NOirw'} />
                </div>
              </div>
              <div className="frame-220" onClick={videoIconClick}>
                <img className="iconsplay_arrow" src="/img/icons-play-arrow.svg" alt="Icons/play_arrow" />
                <div className="video">{video}</div>
              </div>
            </div>
            <div className="frame-223">
              <img ref={this.patientStoryRef}
                className="air-touch-n20-outsid"
                src={airtouchN20Outsidetable60591200X800}
                alt="AirTouch N20-OutsideTable-6059-1200x800-5b2df79"
              />
              <div className="frame-1" style={{'paddingTop': '8px'}}>
                <div className="frame">
                  <p className="patient-story-from sfprodisplay-normal-log-cabin-20px">{patientStoryFrom2}</p>
                  <p className="name sfprotext-regular-normal-log-cabin-14px">{name}</p>
                  <div className="button" onClick={changeReadMoreOSA} style={{'marginTop': '0px', 'alignItems': 'start', 'justifyContent': 'start'}}>
                    <div className="text-button sfprotext-normal-science-blue-16px">{!readMoreOSA ? textButton3 : textButton1 }</div>
                    <img className="chevron-1" src={!readMoreOSA ? "/img/chevron-3.svg" : "/img/chevron-1.svg"} alt="Chevron" />
                  </div>
                  { readMoreOSA && 
                  <div className="tell-us-about-your-s" >
                    <span className="see-more-title">Tell us about your symptoms</span>
                    <br />
                    <span className="see-more-text">I was permanently tired, and after getting home from work, I’d have my tea and then fall
                    asleep on the sofa within half an hour or so. I was missing out on that important family time, often
                    ending up in bed before my daughter. The weekends were even worse, and I’d tend to nod off regularly
                    as I was more relaxed.</span>
                    <br />
                    <br />
                    <span className="see-more-title">What led to your diagnosis?</span>
                    <br />
                    <span className="see-more-text">The lack of energy and the fact I was missing out on spending time with my daughter was becoming a
                    problem – my wife was understandably becoming fed up. Discussions with my wife and my father had led
                    to me booking an appointment with the doctor.</span>
                    <br />
                    <br />
                    <span className="see-more-title">What happened next?</span>
                    <br />
                    <span className="see-more-text">The doctor gave me some information about sleep apnoea, and it was obvious at that point that
                    symptoms I was suffering were the very same symptoms described in the information leaflet.</span>
                    <br />
                    <br />
                    <span className="see-more-title">How did you get on to treatment?</span>
                    <br />
                    <span className="see-more-text">Again, I was offered to go through the NHS, which would have involved a wait, or told that the
                    clinic could provide equipment for me privately. I chose the latter option as I wanted to begin
                    treatment straight away and received a ResMed AirSense 10 and began treatment within a week.</span>
                    <br />
                    <br />
                    <span className="see-more-title">What was the impact of your treatment on your home life?</span>
                    <br />
                    <span className="see-more-text">Within 24/48 hours, the impact was incredible. I had more energy. I was able to spend quality time
                    with my family beyond struggling through work. I very quickly felt that I had got control of my
                    sleep. I also felt reassured that there was someone checking my equipment remotely and making
                    adjustments to my treatment to ensure I was getting the full benefit.*</span>
                    <br />
                    <br />
                    <span className="see-more-note">*Please note that Ed Jones is a private patient with ResMed</span>
                    <br />
                    <br />
                    <span className="see-more-title">And on your health?</span>
                    <br /> <span className="see-more-text">I was very overweight at the time of my diagnosis and had discussed this with my doctor who
                    advised me to focus on getting my sleep apnoea sorted before addressing my weight. Once on treatment
                    and with renewed energy, a personal trainer friend of mine suggested that I start walking regularly.
                    I took this advice and started with just 1-2 miles at the park and quite quickly built it up to 6-7
                    miles every couple of days. I immediately started to see and feel the benefits of the exercise
                    routine and signed up to do a charity walk from Manchester to Sheffield.</span>
                    <br />
                    <br />
                    <span className="see-more-title">How are you getting on with treatment today?</span>
                    <br /> <span className="see-more-text">I remember thinking ‘I am not sure I can adjust to this’ when I started treatment, and that,
                    occasionally, it feels like a bit of a burden. However, when I consider that 2-3 years ago I would
                    struggle to walk up the road compared to what I’m able to do now, it’s a good reminder to help me
                    keep on track with my treatment as I don’t want to go back to where I was!</span>
                    <br />
                    <br />
                    <span className="see-more-title">So what’s next for you?</span>
                    <br />
                    <span className="see-more-text">I’m really enjoying fundraising and being able to give something back. Last year I completed a
                    section of the Great Wall of China, and I’m just about to set off to the French Alps to complete a
                    Trek involving 3 countries in 3 days.</span>
                    <br />
                    <br />
                    <span className="see-more-title">And finally, what would you say to anyone starting treatment?</span>
                    <br />
                    <div className="see-more-text">Listen to the experts and seek out the best advice. I cannot fault the quality of service and advice
                    that I’ve had from ResMed.</div>
                    <br />
                    <div className="button" onClick={changeReadMoreOSA} style={{'marginTop': '0px', 'justifyContent': 'start', 'marginBottom': '32px'}}>
                    <div className="text-button sfprotext-normal-science-blue-16px">{!readMoreOSA ? textButton3 : textButton1 }</div>
                    <img className="chevron-1" src={!readMoreOSA ? "/img/chevron-3.svg" : "/img/chevron-1.svg"} alt="Chevron" />
                  </div>
                  </div>
                    }
                </div>
              </div>
              <div className="frame-219">
                <IconspersonOutline24px />
                <div className="patient-story" style={{'marginTop': '1px'}}>{patientStory}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="frame-249">
          <div ref={this.airconnectRef} className="air-connect-fa-qs sfprodisplay-normal-log-cabin-20px">{airconnectFaqs2}</div>
          <Frame232 faqQuestion={frame2321Props.faqQuestion} faqContents="ResMed is a global leader in digital health and sleep apnoea. Our digital health technologies and cloud-connected medical devices help transform care for people with sleep apnoea, and other chronic diseases in over 140 countries." />
          <Frame232 faqQuestion={frame2322Props.faqQuestion} faqContents="AirConnect is a service provided to the National Health Service (NHS) by ResMed. It is a virtual care service that supports patients with their sleep apnoea treatment."/>
          <Frame232 faqQuestion={frame2323Props.faqQuestion} className={frame2323Props.className} faqContents="Obstructive sleep apnoea (OSA) occurs when the muscles and soft tissues of the throat relax during sleep and block the upper airway. It’s often accompanied by loud snoring or snorting. OSA is the most common type of sleep apnoea.³ "/>
          <Frame232 faqQuestion={frame2324Props.faqQuestion} faqContents="Your hospital believes that you could benefit from sleep apnoea treatment. Please contact your healthcare provider directly if you want to discuss your enrolment or treatment plan."/>
          <Frame232 faqQuestion={frame2325Props.faqQuestion} className={frame2325Props.className} useOL={true}/>
          <Frame232 faqQuestion={frame2326Props.faqQuestion} faqContents="myAir is a sleep treatment support app that enables you to track your sleep treatment with your AirSense™ or AirCurve™ machine. myAir also helps you to understand your progress, learn how to use your mask and CPAP machine properly and troubleshoot common issues independently. "/>
          <Frame232 faqQuestion={frame2327Props.faqQuestion} className={frame2327Props.className} faqContents="Positive airway pressure (PAP) therapy, commonly called continuous positive airway pressure (CPAP) therapy, works by delivering pressurised air through your airway, keeping it open and preventing sleep apnoea from occurring.⁴ All types of PAP treatment usually take the form of a machine or device. The machine is connected to a mask using a tube, and the mask is worn by the sleep apnoea patient to deliver the air generated by the machine. "/>
        </div>
        <div className="overlap-group1" style={{'marginTop': '8px'}}>
          <div className="frame-225">
            <div className="rectangle-14"></div>
            <div className="frame-209">
              <div className="sources">{sources}</div>
              <p className="lal-c-strange-c-ba sfprotext-regular-normal-black-12px">
              <ul style={{'listStyle': 'decimal'}}>
                <li style={{'listStyle': 'decimal', 'display': 'list-item'}}>Lal C, Strange C, Bachman D. Neurocognitive Impairment in Obstructive Sleep Apnea. CHEST Journal, June 2012, viewed on June 26, 2023, <a href="https://journal.chestnet.org/article/S0012-3692(12)60352-3/fulltext">https://journal.chestnet.org/article/S0012-3692(12)60352-3/fulltext</a></li>
                <br />
                <li style={{'listStyle': 'decimal', 'display': 'list-item'}}>Adherence to CPAP therapy improves quality of life and reduces symptoms among obstea syndrome patients. Viewed 14th Juructive sleep apnne 2022 - <a href="https://pubmed.ncbi.nlm.nih.gov/21667216/">https://pubmed.ncbi.nlm.nih.gov/21667216/</a></li>
                <br />
                <li style={{'listStyle': 'decimal', 'display': 'list-item'}}>U.S Food & Drug Administration. Always Tired? You May Have Sleep Apnea. U.S Food & Drug Administration, 2021, viewed May 10, 2023, <a href="https://www.fda.gov/consumers/consumer-updates/always-tired-you-may-have-sleep-apnea">https://www.fda.gov/consumers/consumer-updates/always-tired-you-may-have-sleep-apnea</a></li>
                <br />
                <li style={{'listStyle': 'decimal', 'display': 'list-item'}}>Pinto V, Sharma S. Continuous Positive Airway Pressure. National Library of Medicine, 2022, viewed July 21, 2023, <a href="https://www.ncbi.nlm.nih.gov/books/NBK482178/">https://www.ncbi.nlm.nih.gov/books/NBK482178/</a></li>
              </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default X11Expanded;
