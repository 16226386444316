import React from "react";
import "./Text.css";

function Text(props) {
  const { children } = props;

  return (
    <div className="text">
      <p className="title-2 sfprodisplay-normal-white-24px">{children}</p>
    </div>
  );
}

export default Text;
