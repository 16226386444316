import "./App.css";
import React from "react";
import { Route, BrowserRouter as Router, Switch} from "react-router-dom";
// V1
import X11Expanded from "./components/X11Expanded";
import X12Expanded from "./components/X12Expanded";
import X12MaskSelected from "./components/X12MaskSelected";
import X13Expanded from './components/X13Expanded';
// V2
import X11ExpandedV2 from "./components/X11ExpandedV2";
import X12ExpandedV2 from "./components/X12ExpandedV2";
import X12MaskSelectedV2 from "./components/X12MaskSelectedV2";
import X13ExpandedV2 from './components/X13ExpandedV2';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <X11Expanded {...x11ExpandedData} />
        </Route>
        {/* Routes V1 */}
        <Route exact path="/welcome">
          <X11Expanded {...x11ExpandedData} />
        </Route>
        <Route exact path="/congratulations">
          <X12Expanded {...x12ExpandedData} />
        </Route>
        <Route exact path="/airfitN20">
          <X12MaskSelected {...x12MaskSelectedN20Data} />
        </Route>
        <Route exact path="/airfitF20">
          <X12MaskSelected {...x12MaskSelectedF20Data} />
        </Route>
        <Route exact path="/quattroAir">
          <X12MaskSelected {...x12MaskSelectedQData} />
        </Route>
        <Route exact path="/arrives">
          <X13Expanded { ...x13ExpandedData } />
        </Route>
        {/* Routes V2 */}
        <Route exact path="/welcomeV2">
          <X11ExpandedV2 {...x11ExpandedDataV2} />
        </Route>
        <Route exact path="/congratulationsV2F20">
          <X12ExpandedV2 {...x12ExpandedDataV2F20} />
        </Route>
        <Route exact path="/congratulationsV2N20">
          <X12ExpandedV2 {...x12ExpandedDataV2N20} />
        </Route>
        <Route exact path="/congratulationsV2Q">
          <X12ExpandedV2 {...x12ExpandedDataV2Q} />
        </Route>
        <Route exact path="/congratulationsV2F30">
          <X12ExpandedV2 {...x12ExpandedDataV2F30} />
        </Route>
        <Route exact path="/congratulationsV2F30i">
          <X12ExpandedV2 {...x12ExpandedDataV2F30i} />
        </Route>
        <Route exact path="/congratulationsV2P10">
          <X12ExpandedV2 {...x12ExpandedDataV2P10} />
        </Route>
        <Route exact path="/airfitN20V2">
          <X12MaskSelectedV2 {...x12MaskSelectedN20DataV2} />
        </Route>
        <Route exact path="/airfitF20V2">
          <X12MaskSelectedV2 {...x12MaskSelectedF20DataV2} />
        </Route>
        <Route exact path="/quattroAirV2">
          <X12MaskSelectedV2 {...x12MaskSelectedQDataV2} />
        </Route>
        <Route exact path="/airfitF30V2">
          <X12MaskSelectedV2 {...x12MaskSelectedF30DataV2} />
        </Route>
        <Route exact path="/airfitF30iV2">
          <X12MaskSelectedV2 {...x12MaskSelectedF30iDataV2} />
        </Route>
        <Route exact path="/airfitP10V2">
          <X12MaskSelectedV2 {...x12MaskSelectedP10DataV2} />
        </Route>
        <Route exact path="/arrivesV2">
          <X13ExpandedV2 { ...x13ExpandedDataV2 } />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
// V1 Data
// X11
const buttonData = {
  button: "Watch video",
};
const learnMoreData = {
  button: "Learn more",
};

const frame2321Data = {
    faqQuestion: "Who is ResMed?",
};

const frame2322Data = {
    faqQuestion: "What is AirConnect?",
};

const frame2323Data = {
    faqQuestion: "What is obstructive sleep apnoea (OSA)?",
    className: "frame-2-3",
};

const frame2324Data = {
    faqQuestion: "Why am I enrolled?",
};

const frame2325Data = {
    faqQuestion: "What are the steps to sleep apnoea therapy?",
    className: "frame-2-4",
};

const frame2326Data = {
    faqQuestion: "What is the myAir app?",
};

const frame2327Data = {
    faqQuestion: "What is PAP therapy for sleep apnoea?",
    className: "frame-2",
};

const x11ExpandedData = {
  title: "Let's get you ready for treatment!",
  wellBeSendingYou: "We’ll be sending you information so you feel confident as you prepare for your CPAP treatment.",
  inThisIssue: "In this issue:",
  whatIsCpap1: "What is CPAP?",
  whatToExpectOnCpapTreatment1: "What to expect on CPAP treatment",
  patientStoryFrom1: "Patient story: From fatigued to adventurer",
  airconnectFaqs1: "AirConnect FAQs",
  whatIsCpap2: "What is CPAP?",
  spanText1: <React.Fragment>CPAP is short for &#x27;continuous positive airway pressure&#x27;. A CPAP machine delivers air to a mask you wear over your nose and/or mouth to help keep your breathing consistent. CPAP machines are primarily used for sleep apnoea, but they are beneficial for other breathing conditions as well.<br /><br /></React.Fragment>,
  spanText2: "During CPAP treatment, a machine pushes a continuous flow of air through a tube to your mask. This helps prevent the stops and pauses in your breathing caused by sleep apnoea.",
  spanText3: " These pauses can disrupt your sleep and put a strain on your heart, among other potential complications.",
  spanText4: <React.Fragment>¹<br /></React.Fragment>,
  spanText5: <React.Fragment><br />Your CPAP machine can help prevent the stops and pauses in your breathing, which can allow you to sleep better and feel more rested. Use your new CPAP equipment regularly to reduce the negative side effects of sleep apnoea.²</React.Fragment>,
  dtc_Maskrefresh2022_2228_Resmed_01_: "/img/dtc-maskrefresh2022-2228-resmed-01-0806-1@2x.png",
  whatToExpectOnCpapTreatment2: "What to expect on CPAP treatment",
  itTookSomeTimeFo: "It took some time for Pamela, who was new to CPAP treatment, to make it part of her routine. But once it did, she noticed that her sleep and health improved, along with her confidence. Watch Pamela’s journey from when she started CPAP treatment for obstructive sleep apnoea (OSA).",
  video: "Video",
  airtouchN20Outsidetable60591200X800: "/img/airtouch-n20-outsidetable-6059-1200x800-5b2df79@2x.png",
  patientStoryFrom2: "Patient story: From fatigued to adventurer",
  name: "Ed Jones is 44 years old and a credit analyst from Cheshire. He was diagnosed with obstructive sleep apnoea (OSA) in February 2015 and also has a heart arrhythmia. Since his diagnosis, Ed has embarked on a series of charity walking events, fundraising more than £13,500, and losing 8 stone in the process.",
  textButton1: "Read less",
  textButton2: "Read less",
  textButton3: "Read more about Ed’s OSA journey",
  patientStory: "Patient story",
  airconnectFaqs2: "AirConnect FAQs",
  sources: "Sources",
  spanText6: "Lal C, Strange C, Bachman D. Neurocognitive Impairment in Obstructive Sleep Apnea. CHEST Journal, June 2012, viewed on June 26, 2023, ",
  spanText7: "https://journal.chestnet.org/article/S0012-3692(12)60352-3/fulltext",
  spanText8: <React.Fragment> <br />Adherence to CPAP therapy improves quality of life and reduces symptoms among obstructive sleep apnea syndrome patients. Viewed 14th June 2022 - </React.Fragment>,
  spanText9: <React.Fragment>https://pubmed.ncbi.nlm.nih.gov/21667216/<br /></React.Fragment>,
  spanText10: <React.Fragment><br />U.S Food &amp; Drug Administration. Always Tired? You May Have Sleep Apnea. U.S Food &amp; Drug Administration, 2021, viewed May 10, 2023, </React.Fragment>,
  spanText11: "https://www.fda.gov/consumers/consumer-updates/always-tired-you-may-have-sleep-apnea",
  spanText12: <React.Fragment><br />Pinto V, Sharma S. Continuous Positive Airway Pressure. National Library of Medicine, 2022, viewed July 21, 2023, https://www.ncbi.nlm.nih.gov/books/NBK482178/</React.Fragment>,
  buttonProps: buttonData,
  frame2321Props: frame2321Data,
  frame2322Props: frame2322Data,
  frame2323Props: frame2323Data,
  frame2324Props: frame2324Data,
  frame2325Props: frame2325Data,
  frame2326Props: frame2326Data,
  frame2327Props: frame2327Data,
};
//X12 - base page
const frame2271Data = {
  airfitF201: "AirFit F20",
  airfitF202: "/img/airfit-f20@2x.png",
};

const frame2272Data = {
  airfitF201: "Quattro Air",
  airfitF202: "/img/quattro-air@3x.jpg",
};

const x12ExpandedData = {
  congratulationsYou: "Congratulations, your treatment equipment has shipped!",
  whileYouWaitForY: "While you wait for your delivery, here is some quick information about the equipment you’ll use and additional tips as you get started in CPAP treatment.",
  inThisIssue: "In this issue:",
  learnMoreAboutYourCpapMachine1: "Learn more about your CPAP machine",
  learnMoreAboutYourMask1: "Learn more about your mask",
  adaptingToYourCpapMachine: "Adapting to your CPAP machine",
  patientStoryUsing1: "Patient story: Using myAir™ to track treatment",
  learnMoreAboutYourCpapMachine2: "Learn more about your CPAP machine",
  spanText1: "Your CPAP machine will be the AirSense™ 10 model. ",
  spanText2: "Watch this video for information about the AirSense 10 and how to set it up.",
  learnMoreAboutYourMask2: "Learn more about your mask",
  airfitN201: "AirFit N20",
  airfitN202: "/img/airfit-n20@2x.png",
  airtouchN20Outsidetable60591200X8001: "/img/airtouch-n20-outsidetable-6059-1200x800-5b2df79@2x.png",
  adaptingToACpapMachine: "Adapting to a CPAP machine",
  startingSleepApnoe: "Starting sleep apnoea treatment isn’t always easy. Give yourself time to adjust to sleeping with a mask, tubing and constant air pressure. Read our tips to help you successfully acclimate to CPAP treatment.",
  readMore1: "Read more",
  readLess2: "Read less",
  article: "Article",
  airtouchN20Outsidetable60591200X8002: "/img/airtouch-n20-outsidetable-6059-1200x800-5b2df79-1@2x.png",
  patientStoryUsing2: "Patient story: Using myAir to track treatment",
  spanText3: "ResMed’s patient engagement tool, the myAir™ app, allows you to monitor your nightly sleep performance, track your progress and stay motivated. ",
  spanText4: <React.Fragment>Download myAir here.<br /></React.Fragment>,
  spanText5: <React.Fragment><br />Watch how myAir keeps Rose up-to-date and on track with her sleep treatment.</React.Fragment>,
  patientStory: "Patient story",
  frame2271Props: frame2271Data,
  frame2272Props: frame2272Data,
  buttonProps: buttonData,
};
// X12 - multi mask
const navbaractionData = {
  children: "Back",
};

const text1Data = {
  children: "How to get a good mask fit",
};

const gradientCard1Data = {
  textProps: text1Data,
};

const text2Data = {
  children: "How to manage leaks",
};

const gradientCard2Data = {
  className: "gradient-card-1",
  textProps: text2Data,
};

const text3DataN20 = {
  children: "AirFit N20 daily cleaning routine",
};

const gradientCard3DataN20 = {
  className: "gradient-card-2",
  textProps: text3DataN20,
};

const text3DataF20 = {
  children: "AirFit F20 daily cleaning routine",
};

const gradientCard3DataF20 = {
  className: "gradient-card-2",
  textProps: text3DataF20,
};

const text3DataQ = {
  children: "Quattro Air cleaning routine",
};

const gradientCard3DataQ = {
  className: "gradient-card-2",
  textProps: text3DataQ,
};

const text4DataN20 = {
  children: "AirFit N20 weekly cleaning routine",
};

const gradientCard4DataN20 = {
  className: "gradient-card-3",
  textProps: text4DataN20,
};

const text4DataF20 = {
  children: "AirFit F20 weekly cleaning routine",
};

const gradientCard4DataF20 = {
  className: "gradient-card-3",
  textProps: text4DataF20,
};

const text4DataQ = {
  children: "Quattro Air weekly cleaning routine",
};

const gradientCard4DataQ = {
  className: "gradient-card-3",
  textProps: text4DataQ,
};

const frame2321Data12 = {
  faqQuestion: "What's the best way to clean my mask?",
};

const frame2322Data12 = {
  faqQuestion: "What cleaners should I avoid when cleaning my mask?",
};

const frame2323Data12 = {
  faqQuestion: "Do I need to clean my mask headgear? If so, how?",
};

const frame2324Data12 = {
  faqQuestion: "Should I clean my CPAP machine?",
};

const x12MaskSelectedN20Data = {
  title: "AirFit N20",
  airfitN20: "/img/airfit-n20@2x.png",
  theAirfitN20Mask: "The AirFit™ N20 mask has a unique fit to meet your specific sleep needs. To be successful in your treatment, you must be able to handle and care for your mask. Watch the following videos to learn how to adjust the headgear, manage air leaks and perform routine daily and weekly cleanings for the AirFit N20.",
  maskFit: "Mask fit",
  cleaning: "Cleaning",
  faqs: "FAQs",
  spanText1: "If you have further questions, read the ",
  spanText2: "AirFit N20 user guide",
  spanText3: ".",
  place: "Back",
  navbaractionProps: navbaractionData,
  gradientCard1Props: gradientCard1Data,
  gradientCard2Props: gradientCard2Data,
  gradientCard3Props: gradientCard3DataN20,
  gradientCard4Props: gradientCard4DataN20,
  frame2321Props: frame2321Data12,
  frame2322Props: frame2322Data12,
  frame2323Props: frame2323Data12,
  frame2324Props: frame2324Data12,
  watchVideo: "Watch video",
  gradient1: "_Q6jTYHy4d8",
  gradient2: "5RGlNAoPVgI",
  gradient3: "5ZoKr8vyLUE",
  gradient4: "ZLL0Zrg1s9U",
  userGuide: "https://shop.resmed.com/medias/sys_master/root/h06/h93/8814538752030/UserGuide-AirFit-N20-EUR1-EN.pdf"
};

const x12MaskSelectedF20Data = {
  title: "AirFit F20",
  airfitN20: "/img/airfit-f20@2x.png",
  theAirfitN20Mask: "The AirFit™ F20 mask has a unique fit to meet your specific sleep needs. To be successful in your treatment, you must be able to handle and care for your mask. Watch the following videos to learn how to adjust the headgear, manage air leaks and perform routine daily and weekly cleanings for the AirFit F20. ",
  maskFit: "Mask fit",
  cleaning: "Cleaning",
  faqs: "FAQs",
  spanText1: "If you have further questions, read the ",
  spanText2: "AirFit F20 user guide",
  spanText3: ".",
  place: "Back",
  navbaractionProps: navbaractionData,
  gradientCard1Props: gradientCard1Data,
  gradientCard2Props: gradientCard2Data,
  gradientCard3Props: gradientCard3DataF20,
  gradientCard4Props: gradientCard4DataF20,
  frame2321Props: frame2321Data12,
  frame2322Props: frame2322Data12,
  frame2323Props: frame2323Data12,
  frame2324Props: frame2324Data12,
  watchVideo: "Watch video",
  gradient1: "a2H0Qj9IrSw",
  gradient2: "pkaNVfImVfE",
  gradient3: "PR1-X_1KyLs",
  gradient4: "HndQD14MuKY",
  userGuide: "https://shop.resmed.com/medias/sys_master/images/images/hc2/ha4/8953495093278/airfit-airtouch-f20-user-guide-eur1-eng.pdf"
};

const x12MaskSelectedQData = {
  title: "Quattro Air",
  airfitN20: "/img/quattro-air@3x.jpg",
  theAirfitN20Mask: "The Quattro™ Air mask has a unique fit to meet your specific sleep needs. To be successful in your treatment, you must be able to handle and care for your mask. Watch the following videos to learn how to adjust the headgear, manage air leaks and perform routine daily and weekly cleanings for the Quattro Air.",
  maskFit: "Mask fit",
  cleaning: "Cleaning",
  faqs: "FAQs",
  spanText1: "If you have further questions, read the ",
  spanText2: "Quattro Air user guide",
  spanText3: ".",
  place: "Back",
  navbaractionProps: navbaractionData,
  gradientCard1Props: gradientCard1Data,
  gradientCard2Props: gradientCard2Data,
  gradientCard3Props: gradientCard3DataQ,
  gradientCard4Props: gradientCard4DataQ,
  frame2321Props: frame2321Data12,
  frame2322Props: frame2322Data12,
  frame2323Props: frame2323Data12,
  frame2324Props: frame2324Data12,
  watchVideo: "Watch video",
  gradient1: "np5fdPsxAPc",
  gradient2: "3DUa56P6lf4",
  gradient3: "bqE9-2Ypme8",
  gradient4: "SKIP",
  gradient4Skip: true,
  gradientUseYoutube: true,
  userGuide: "https://document.resmed.com/documents/uk/628098_quattro-air-nv_user-guide_eur_eng.pdf"
};

// x13

const frame1981Data = {
  learnMoreAboutYourMask: "Learn more about your mask",
  onceYourEquipment: "Select the mask included in your CPAP equipment shipment. You can refer to the information in your text messages. Learn about fitting, cleaning and managing leaks for your mask and access the user guide:",
};


const frame1982Data = {
  learnMoreAboutYourMask: "Getting started with myAir",
  onceYourEquipment: <React.Fragment>As you set up your new CPAP equipment, don’t forget to install the myAir™ app. After installing the app, register for an account using the serial number on your AirSense™ 10.<br /><br />Each night that you use your CPAP machine, your sleep data automatically syncs from your equipment to your account after you wake up. The myAir app uses that information to track your progress and provide timely feedback, helping you get the most out of your CPAP treatment.<br /><br />Check the app each day to see your sleep score and coaching advice.</React.Fragment>,
};

const x13ExpandedData = {
  title: "Your CPAP equipment arrives soon!",
  inThisIssueWeve: "In this issue, we’ve included some more tips for starting and acclimating to CPAP treatment.",
  inThisIssue: "In this issue:",
  learnMoreAboutYourMask: "Learn more about your mask",
  gettingStartedWithMyair: "Getting started with myAir",
  sleepingWithACpapMachine1: "Sleeping with a CPAP machine",
  tipsForBuildingYo: "Tips for building your confidence in CPAP treatment",
  airfitN201: "AirFit N20",
  airfitN202: "/img/airfit-n20@2x.png",
  airtouchN20Outsidetable60591200X800: "/img/airtouch-n20-outsidetable-6059-1200x800-5b2df79-1@2x copy.png",
  sleepingWithACpapMachine2: "Sleeping with a CPAP machine",
  adaptingToCpapThe: <React.Fragment>Adapting to CPAP treatment isn’t always easy. Many people with sleep apnoea will tell you they hated their CPAP mask the first time they tried it. You may feel hesitant about wearing the mask to sleep or fear you’ll never find a comfortable sleeping position.<br /><br />The good news: You’re not alone.<br /><br />The better news: There are plenty of tips and tricks to make it easier to adjust to your sleep apnoea treatment.</React.Fragment>,
  read7TipsForAdju: "Read 7 tips for adjusting to CPAP treatment",
  readLess: "Read less",
  article: "Article",
  dtc_Maskrefresh2022_2228_Resmed_01_: "/img/dtc-maskrefresh2022-2228-resmed-01-0806-1@2x.png",
  tipsForBuildingConfidence: "Tips for building confidence",
  itMayFeelAwkward: "It may feel awkward at first, but with perseverance and utilising the tips you’ve learned, you’ll soon be getting great sleep with your CPAP machine. Listen to Pamela explain the challenges she faced when she started CPAP treatment and how building a routine with her equipment helped build her confidence.",
  video: "Video",
  mostPeopleWhoUse: <React.Fragment>Most people who use CPAP treatment agree that it takes some getting used to. But they also say it’s worth it. Like anything new, you’ve got to give it time.<br /><br />You’ve got this.</React.Fragment>,
  sleepWellTheMyairTeam: <React.Fragment>Sleep well!<br />The myAir Team</React.Fragment>,
  sources: "(sources)",
  frame1981Props: frame1981Data,
  frame2271Props: frame2271Data,
  frame2272Props: frame2272Data,
  frame1982Props: frame1982Data,
  buttonProps: buttonData,
};

//V2 ********************************************************************
// X11
const buttonDataV2 = {
  button: "Watch video",
};

const frame2321DataV2 = {
  faqQuestion: "Who is ResMed?",
};

const frame2322DataV2 = {
  faqQuestion: "What is AirConnect?",
};

const frame2323DataV2 = {
  faqQuestion: "What is obstructive sleep apnoea (OSA)?",
  className: "frame-2-3",
};

const frame2324DataV2 = {
  faqQuestion: "Why am I enrolled?",
};

const frame2325DataV2 = {
  faqQuestion: "What are the steps to sleep apnoea therapy?",
  className: "frame-2-4",
};

const frame2326DataV2 = {
  faqQuestion: "What is the myAir app?",
};

const frame2327DataV2 = {
  faqQuestion: "What is PAP therapy for sleep apnoea?",
  className: "frame-2",
};
const x11ExpandedDataV2 = {
  title: "Let's get you ready for treatment!",
  wellBeSendingYou: "We’ll be sending you information so you feel confident as you prepare for your CPAP treatment.",
  inThisIssue: "In this issue:",
  whatIsCpap1: "What is CPAP?",
  whatToExpectOnCpapTreatment1: "What to expect on CPAP treatment",
  patientStoryFrom1: "Patient story: From fatigued to adventurer",
  airconnectFaqs1: "AirConnect FAQs",
  whatIsCpap2: "What is CPAP?",
  spanText1: <React.Fragment>CPAP is short for &#x27;continuous positive airway pressure&#x27;. A CPAP machine delivers air to a mask you wear over your nose and/or mouth to help keep your breathing consistent. CPAP machines are primarily used for sleep apnoea, but they are beneficial for other breathing conditions as well.<br /><br /></React.Fragment>,
  spanText2: "During CPAP treatment, a machine pushes a continuous flow of air through a tube to your mask. This helps prevent the stops and pauses in your breathing caused by sleep apnoea.",
  spanText3: " These pauses can disrupt your sleep and put a strain on your heart, among other potential complications.",
  spanText4: <React.Fragment>¹<br /></React.Fragment>,
  spanText5: <React.Fragment><br />Your CPAP machine can help prevent the stops and pauses in your breathing, which can allow you to sleep better and feel more rested. Use your new CPAP equipment regularly to reduce the negative side effects of sleep apnoea.²</React.Fragment>,
  dtc_Maskrefresh2022_2228_Resmed_01_: "/img/dtc-maskrefresh2022-2228-resmed-01-0806-1@2x.png",
  whatToExpectOnCpapTreatment2: "What to expect on CPAP treatment",
  itTookSomeTimeFo: "It took some time for Pamela, who was new to CPAP treatment, to make it part of her routine. But once it did, she noticed that her sleep and health improved, along with her confidence. Watch Pamela’s journey from when she started CPAP treatment for obstructive sleep apnoea (OSA).",
  video: "Video",
  airtouchN20Outsidetable60591200X800: "/img/airtouch-n20-outsidetable-6059-1200x800-5b2df79@2x.png",
  patientStoryFrom2: "Patient story: From fatigued to adventurer",
  name: "Ed Jones is 44 years old and a credit analyst from Cheshire. He was diagnosed with obstructive sleep apnoea (OSA) in February 2015 and also has a heart arrhythmia. Since his diagnosis, Ed has embarked on a series of charity walking events, fundraising more than £13,500, and losing 8 stone in the process.",
  textButton1: "Read less",
  textButton2: "Read less",
  textButton3: "Read more about Ed’s OSA journey",
  patientStory: "Patient story",
  airconnectFaqs2: "AirConnect FAQs",
  sources: "Sources",
  spanText6: "Lal C, Strange C, Bachman D. Neurocognitive Impairment in Obstructive Sleep Apnea. CHEST Journal, June 2012, viewed on June 26, 2023, ",
  spanText7: "https://journal.chestnet.org/article/S0012-3692(12)60352-3/fulltext",
  spanText8: <React.Fragment> <br />Adherence to CPAP therapy improves quality of life and reduces symptoms among obstructive sleep apnea syndrome patients. Viewed 14th June 2022 - </React.Fragment>,
  spanText9: <React.Fragment>https://pubmed.ncbi.nlm.nih.gov/21667216/<br /></React.Fragment>,
  spanText10: <React.Fragment><br />U.S Food &amp; Drug Administration. Always Tired? You May Have Sleep Apnea. U.S Food &amp; Drug Administration, 2021, viewed May 10, 2023, </React.Fragment>,
  spanText11: "https://www.fda.gov/consumers/consumer-updates/always-tired-you-may-have-sleep-apnea",
  spanText12: <React.Fragment><br />Pinto V, Sharma S. Continuous Positive Airway Pressure. National Library of Medicine, 2022, viewed July 21, 2023, https://www.ncbi.nlm.nih.gov/books/NBK482178/</React.Fragment>,
  buttonProps: buttonData,
  frame2321Props: frame2321DataV2,
  frame2322Props: frame2322DataV2,
  frame2323Props: frame2323DataV2,
  frame2324Props: frame2324DataV2,
  frame2325Props: frame2325DataV2,
  frame2326Props: frame2326DataV2,
  frame2327Props: frame2327DataV2,
};

//X12 - base page
const frame2271DataV2 = {
airfitF201: "AirFit F20",
airfitF202: "/img/airfit-f20@2x.png",
};

const frame2272DataV2 = {
airfitF201: "Quattro Air",
airfitF202: "/img/quattro-air@3x.jpg",
};

const x12ExpandedDataV2N20 = {
congratulationsYou: "Congratulations, your treatment equipment has shipped!",
whileYouWaitForY: "While you wait for your delivery, here is some quick information about the equipment you’ll use and additional tips as you get started in CPAP treatment.",
inThisIssue: "In this issue:",
learnMoreAboutYourCpapMachine1: "Learn more about your CPAP machine",
learnMoreAboutYourMask1: "Learn more about your mask",
adaptingToYourCpapMachine: "Adapting to your CPAP machine",
patientStoryUsing1: "Patient story: Using myAir to track treatment",
learnMoreAboutYourCpapMachine2: "Learn more about your CPAP machine",
spanText1: "Your CPAP machine will be the AirSense 10 model. ",
spanText2: "Watch this video for information about the AirSense 10 and how to set it up.",
learnMoreAboutYourMask2: "Learn more about your mask",
airfitN201: "AirFit N20",
airfitN202: "/img/airfit-n20@2x.png",
airtouchN20Outsidetable60591200X8001: "/img/airtouch-n20-outsidetable-6059-1200x800-5b2df79@2x.png",
adaptingToACpapMachine: "Adapting to a CPAP machine",
startingSleepApnoe: "Starting sleep apnoea treatment isn’t always easy. Give yourself time to adjust to sleeping with a mask, tubing and constant air pressure. Read our tips to help you successfully acclimate to CPAP treatment.",
readMore1: "Read more",
readLess2: "Read less",
article: "Article",
airtouchN20Outsidetable60591200X8002: "/img/airtouch-n20-outsidetable-6059-1200x800-5b2df79-1@2x.png",
patientStoryUsing2: "Patient story: Using myAir to track treatment",
spanText3: "ResMed’s patient engagement tool, the myAir™ app, allows you to monitor your nightly sleep performance, track your progress and stay motivated. ",
spanText4: <React.Fragment>Download myAir here.<br /></React.Fragment>,
spanText5: <React.Fragment><br />Watch how myAir keeps Rose up-to-date and on track with her sleep treatment.</React.Fragment>,
patientStory: "Patient story",
frame2271Props: frame2271DataV2,
frame2272Props: frame2272DataV2,
buttonProps: buttonData,
pathToMask: '/airfitN20V2'
};

const x12ExpandedDataV2F20 = {
congratulationsYou: "Congratulations, your treatment equipment has shipped!",
whileYouWaitForY: "While you wait for your delivery, here is some quick information about the equipment you’ll use and additional tips as you get started in CPAP treatment.",
inThisIssue: "In this issue:",
learnMoreAboutYourCpapMachine1: "Learn more about your CPAP machine",
learnMoreAboutYourMask1: "Learn more about your mask",
adaptingToYourCpapMachine: "Adapting to your CPAP machine",
patientStoryUsing1: "Patient story: Using myAir to track treatment",
learnMoreAboutYourCpapMachine2: "Learn more about your CPAP machine",
spanText1: "Your CPAP machine will be the AirSense 10 model. ",
spanText2: "Watch this video for information about the AirSense 10 and how to set it up.",
learnMoreAboutYourMask2: "Learn more about your mask",
airfitN201: "AirFit F20",
airfitN202: "/img/airfit-f20@2x.png",
airtouchN20Outsidetable60591200X8001: "/img/airtouch-n20-outsidetable-6059-1200x800-5b2df79@2x.png",
adaptingToACpapMachine: "Adapting to a CPAP machine",
startingSleepApnoe: "Starting sleep apnoea treatment isn’t always easy. Give yourself time to adjust to sleeping with a mask, tubing and constant air pressure. Read our tips to help you successfully acclimate to CPAP treatment.",
readMore1: "Read more",
readLess2: "Read less",
article: "Article",
airtouchN20Outsidetable60591200X8002: "/img/airtouch-n20-outsidetable-6059-1200x800-5b2df79-1@2x.png",
patientStoryUsing2: "Patient story: Using myAir to track treatment",
spanText3: "ResMed’s patient engagement tool, the myAir™ app, allows you to monitor your nightly sleep performance, track your progress and stay motivated. ",
spanText4: <React.Fragment>Download myAir here.<br /></React.Fragment>,
spanText5: <React.Fragment><br />Watch how myAir keeps Rose up-to-date and on track with her sleep treatment.</React.Fragment>,
patientStory: "Patient story",
frame2271Props: frame2271DataV2,
frame2272Props: frame2272DataV2,
buttonProps: buttonData,
pathToMask: '/airfitF20V2'
};
const x12ExpandedDataV2Q = {
congratulationsYou: "Congratulations, your treatment equipment has shipped!",
whileYouWaitForY: "While you wait for your delivery, here is some quick information about the equipment you’ll use and additional tips as you get started in CPAP treatment.",
inThisIssue: "In this issue:",
learnMoreAboutYourCpapMachine1: "Learn more about your CPAP machine",
learnMoreAboutYourMask1: "Learn more about your mask",
adaptingToYourCpapMachine: "Adapting to your CPAP machine",
patientStoryUsing1: "Patient story: Using myAir to track treatment",
learnMoreAboutYourCpapMachine2: "Learn more about your CPAP machine",
spanText1: "Your CPAP machine will be the AirSense 10 model. ",
spanText2: "Watch this video for information about the AirSense 10 and how to set it up.",
learnMoreAboutYourMask2: "Learn more about your mask",
airfitN201: "Quattro Air",
airfitN202: "/img/quattro-air@3x.jpg",
airtouchN20Outsidetable60591200X8001: "/img/airtouch-n20-outsidetable-6059-1200x800-5b2df79@2x.png",
adaptingToACpapMachine: "Adapting to a CPAP machine",
startingSleepApnoe: "Starting sleep apnoea treatment isn’t always easy. Give yourself time to adjust to sleeping with a mask, tubing and constant air pressure. Read our tips to help you successfully acclimate to CPAP treatment.",
readMore1: "Read more",
readLess2: "Read less",
article: "Article",
airtouchN20Outsidetable60591200X8002: "/img/airtouch-n20-outsidetable-6059-1200x800-5b2df79-1@2x.png",
patientStoryUsing2: "Patient story: Using myAir to track treatment",
spanText3: "ResMed’s patient engagement tool, the myAir™ app, allows you to monitor your nightly sleep performance, track your progress and stay motivated. ",
spanText4: <React.Fragment>Download myAir here.<br /></React.Fragment>,
spanText5: <React.Fragment><br />Watch how myAir keeps Rose up-to-date and on track with her sleep treatment.</React.Fragment>,
patientStory: "Patient story",
frame2271Props: frame2271DataV2,
frame2272Props: frame2272DataV2,
buttonProps: buttonData,
pathToMask: '/quattroAirV2'
};

const x12ExpandedDataV2F30 = {
congratulationsYou: "Congratulations, your treatment equipment has shipped!",
whileYouWaitForY: "While you wait for your delivery, here is some quick information about the equipment you’ll use and additional tips as you get started in CPAP treatment.",
inThisIssue: "In this issue:",
learnMoreAboutYourCpapMachine1: "Learn more about your CPAP machine",
learnMoreAboutYourMask1: "Learn more about your mask",
adaptingToYourCpapMachine: "Adapting to your CPAP machine",
patientStoryUsing1: "Patient story: Using myAir to track treatment",
learnMoreAboutYourCpapMachine2: "Learn more about your CPAP machine",
spanText1: "Your CPAP machine will be the AirSense 10 model. ",
spanText2: "Watch this video for information about the AirSense 10 and how to set it up.",
learnMoreAboutYourMask2: "Learn more about your mask",
airfitN201: "AirFit F30",
airfitN202: "/img/AirFitF30.png",
airtouchN20Outsidetable60591200X8001: "/img/airtouch-n20-outsidetable-6059-1200x800-5b2df79@2x.png",
adaptingToACpapMachine: "Adapting to a CPAP machine",
startingSleepApnoe: "Starting sleep apnoea treatment isn’t always easy. Give yourself time to adjust to sleeping with a mask, tubing and constant air pressure. Read our tips to help you successfully acclimate to CPAP treatment.",
readMore1: "Read more",
readLess2: "Read less",
article: "Article",
airtouchN20Outsidetable60591200X8002: "/img/airtouch-n20-outsidetable-6059-1200x800-5b2df79-1@2x.png",
patientStoryUsing2: "Patient story: Using myAir to track treatment",
spanText3: "ResMed’s patient engagement tool, the myAir™ app, allows you to monitor your nightly sleep performance, track your progress and stay motivated. ",
spanText4: <React.Fragment>Download myAir here.<br /></React.Fragment>,
spanText5: <React.Fragment><br />Watch how myAir keeps Rose up-to-date and on track with her sleep treatment.</React.Fragment>,
patientStory: "Patient story",
frame2271Props: frame2271DataV2,
frame2272Props: frame2272DataV2,
buttonProps: buttonData,
pathToMask: '/airfitF30V2'
};

const x12ExpandedDataV2F30i = {
congratulationsYou: "Congratulations, your treatment equipment has shipped!",
whileYouWaitForY: "While you wait for your delivery, here is some quick information about the equipment you’ll use and additional tips as you get started in CPAP treatment.",
inThisIssue: "In this issue:",
learnMoreAboutYourCpapMachine1: "Learn more about your CPAP machine",
learnMoreAboutYourMask1: "Learn more about your mask",
adaptingToYourCpapMachine: "Adapting to your CPAP machine",
patientStoryUsing1: "Patient story: Using myAir to track treatment",
learnMoreAboutYourCpapMachine2: "Learn more about your CPAP machine",
spanText1: "Your CPAP machine will be the AirSense 10 model. ",
spanText2: "Watch this video for information about the AirSense 10 and how to set it up.",
learnMoreAboutYourMask2: "Learn more about your mask",
airfitN201: "AirFit F30i",
airfitN202: "/img/AirFitF30i.png",
airtouchN20Outsidetable60591200X8001: "/img/airtouch-n20-outsidetable-6059-1200x800-5b2df79@2x.png",
adaptingToACpapMachine: "Adapting to a CPAP machine",
startingSleepApnoe: "Starting sleep apnoea treatment isn’t always easy. Give yourself time to adjust to sleeping with a mask, tubing and constant air pressure. Read our tips to help you successfully acclimate to CPAP treatment.",
readMore1: "Read more",
readLess2: "Read less",
article: "Article",
airtouchN20Outsidetable60591200X8002: "/img/airtouch-n20-outsidetable-6059-1200x800-5b2df79-1@2x.png",
patientStoryUsing2: "Patient story: Using myAir to track treatment",
spanText3: "ResMed’s patient engagement tool, the myAir™ app, allows you to monitor your nightly sleep performance, track your progress and stay motivated. ",
spanText4: <React.Fragment>Download myAir here.<br /></React.Fragment>,
spanText5: <React.Fragment><br />Watch how myAir keeps Rose up-to-date and on track with her sleep treatment.</React.Fragment>,
patientStory: "Patient story",
frame2271Props: frame2271DataV2,
frame2272Props: frame2272DataV2,
buttonProps: buttonData,
pathToMask: '/airfitF30iV2'
};

const x12ExpandedDataV2P10 = {
congratulationsYou: "Congratulations, your treatment equipment has shipped!",
whileYouWaitForY: "While you wait for your delivery, here is some quick information about the equipment you’ll use and additional tips as you get started in CPAP treatment.",
inThisIssue: "In this issue:",
learnMoreAboutYourCpapMachine1: "Learn more about your CPAP machine",
learnMoreAboutYourMask1: "Learn more about your mask",
adaptingToYourCpapMachine: "Adapting to your CPAP machine",
patientStoryUsing1: "Patient story: Using myAir to track treatment",
learnMoreAboutYourCpapMachine2: "Learn more about your CPAP machine",
spanText1: "Your CPAP machine will be the AirSense 10 model. ",
spanText2: "Watch this video for information about the AirSense 10 and how to set it up.",
learnMoreAboutYourMask2: "Learn more about your mask",
airfitN201: "AirFit P10",
airfitN202: "/img/AirFitP10.png",
airtouchN20Outsidetable60591200X8001: "/img/airtouch-n20-outsidetable-6059-1200x800-5b2df79@2x.png",
adaptingToACpapMachine: "Adapting to a CPAP machine",
startingSleepApnoe: "Starting sleep apnoea treatment isn’t always easy. Give yourself time to adjust to sleeping with a mask, tubing and constant air pressure. Read our tips to help you successfully acclimate to CPAP treatment.",
readMore1: "Read more",
readLess2: "Read less",
article: "Article",
airtouchN20Outsidetable60591200X8002: "/img/airtouch-n20-outsidetable-6059-1200x800-5b2df79-1@2x.png",
patientStoryUsing2: "Patient story: Using myAir to track treatment",
spanText3: "ResMed’s patient engagement tool, the myAir™ app, allows you to monitor your nightly sleep performance, track your progress and stay motivated. ",
spanText4: <React.Fragment>Download myAir here.<br /></React.Fragment>,
spanText5: <React.Fragment><br />Watch how myAir keeps Rose up-to-date and on track with her sleep treatment.</React.Fragment>,
patientStory: "Patient story",
frame2271Props: frame2271DataV2,
frame2272Props: frame2272DataV2,
buttonProps: buttonData,
pathToMask: '/airfitP10V2'
};
// X12 - multi mask
const navbaractionDataV2 = {
children: "Back",
};

const text1DataV2 = {
children: "How to get a good mask fit",
};

const gradientCard1DataV2 = {
textProps: text1DataV2,
};

const text2DataV2 = {
children: "How to manage leaks",
};

const gradientCard2DataV2 = {
className: "gradient-card-1",
textProps: text2DataV2,
};

const text3DataN20V2 = {
  children: "AirFit N20 daily cleaning routine",
  };
  
const gradientCard3DataN20V2 = {
className: "gradient-card-2",
textProps: text3DataN20V2,
};

const text3DataF30iV2 = {
children: "AirFit F30i daily cleaning routine",
};

const gradientCard3DataF30iV2 = {
className: "gradient-card-2",
textProps: text3DataF30iV2,
};

const text3DataF20V2 = {
children: "AirFit F20 daily cleaning routine",
};

const gradientCard3DataF20V2 = {
className: "gradient-card-2",
textProps: text3DataF20V2,
};

const text3DataQV2 = {
children: "Quattro Air cleaning routine",
};

const gradientCard3DataQV2 = {
className: "gradient-card-2",
textProps: text3DataQV2,
};

const text3DataF30 = {
  children: "AirFit F30 daily cleaning routine",
};
const gradientCard3DataF30V2 = {
  className: "gradient-card-2",
  textProps: text3DataF30,
};

const text3DataP10 = {
  children: "AirFit P10 daily cleaning routine",
};
const gradientCard3DataP10V2 = {
  className: "gradient-card-2",
  textProps: text3DataP10,
};


const text4DataN20V2 = {
children: "AirFit N20 weekly cleaning routine",
};

const gradientCard4DataN20V2 = {
className: "gradient-card-3",
textProps: text4DataN20V2,
};

const text4DataF30iV2 = {
  children: "AirFit F30i weekly cleaning routine",
  };
  
  const gradientCard4DataF30iV2 = {
  className: "gradient-card-3",
  textProps: text4DataF30iV2,
  };

const text4DataF30V2 = {
  children: "AirFit F30 weekly cleaning routine",
  };
  
  const gradientCard4DataF30V2 = {
  className: "gradient-card-3",
  textProps: text4DataF30V2,
  };

const text4DataF20V2 = {
  children: "AirFit F20 weekly cleaning routine",
  };
  
  const gradientCard4DataF20V2 = {
  className: "gradient-card-3",
  textProps: text4DataF20V2,
  };

const text4DataP10V2 = {
  children: "AirFit P10 weekly cleaning routine",
  };
  
  const gradientCard4DataP10V2 = {
  className: "gradient-card-3",
  textProps: text4DataP10V2,
  };

const text4DataQV2 = {
children: "Quattro Air weekly cleaning routine",
};

const gradientCard4DataQV2 = {
className: "gradient-card-3",
textProps: text4DataQV2,
};

const frame2321Data12V2 = {
faqQuestion: "What's the best way to clean my mask?",
};

const frame2322Data12V2 = {
faqQuestion: "What cleaners should I avoid when cleaning my mask?",
};

const frame2323Data12V2 = {
faqQuestion: "Do I need to clean my mask headgear? If so, how?",
};

const frame2324Data12V2 = {
faqQuestion: "Should I clean my CPAP machine?",
};

const x12MaskSelectedN20DataV2 = {
title: "AirFit N20",
airfitN20: "/img/airfit-n20@2x.png",
theAirfitN20Mask: <React.Fragment>Your AirFit™ N20 is a nasal mask with a silicone cushion for a comfortable fit and secure air pressure seal. It’s a great choice for side sleepers and fits a wide range of facial features.<br/><br/>To be successful in your therapy, you must be able to handle and care for your particular mask. Watch the following videos to learn how to adjust the headgear, manage air leaks, and perform routine daily and weekly cleanings for the AirFit N20.</React.Fragment>,
maskFit: "Mask fit",
cleaning: "Cleaning",
faqs: "FAQs",
spanText1: "If you have further questions, read the ",
spanText2: "AirFit N20 user guide",
spanText3: ".",
place: "Back",
navbaractionProps: navbaractionDataV2,
gradientCard1Props: gradientCard1DataV2,
gradientCard2Props: gradientCard2DataV2,
gradientCard3Props: gradientCard3DataN20V2,
gradientCard4Props: gradientCard4DataN20V2,
frame2321Props: frame2321Data12V2,
frame2322Props: frame2322Data12V2,
frame2323Props: frame2323Data12V2,
frame2324Props: frame2324Data12V2,
watchVideo: "Watch video",
gradient1: "_Q6jTYHy4d8",
gradient2: "5RGlNAoPVgI",
gradient3: "5ZoKr8vyLUE",
gradient4: "ZLL0Zrg1s9U",
userGuide: "https://shop.resmed.com/medias/sys_master/root/h06/h93/8814538752030/UserGuide-AirFit-N20-EUR1-EN.pdf",
backPath: "/congratulationsV2N20"
};

const x12MaskSelectedF20DataV2 = {
title: "AirFit F20",
airfitN20: "/img/airfit-f20@2x.png",
theAirfitN20Mask: <React.Fragment>Your AirFit™ F20 is a full face mask with a silicone cushion for a comfortable fit and secure air pressure seal. It’s a great choice if you have difficulty breathing through your nose while you sleep. <br/><br/>To be successful in your therapy, you must be able to handle and care for your particular mask. Watch the following videos to learn how to adjust the headgear, manage air leaks, and perform routine daily and weekly cleanings for the AirFit F20.</React.Fragment>,
maskFit: "Mask fit",
cleaning: "Cleaning",
faqs: "FAQs",
spanText1: "If you have further questions, read the ",
spanText2: "AirFit F20 user guide",
spanText3: ".",
place: "Back",
navbaractionProps: navbaractionDataV2,
gradientCard1Props: gradientCard1DataV2,
gradientCard2Props: gradientCard2DataV2,
gradientCard3Props: gradientCard3DataF20V2,
gradientCard4Props: gradientCard4DataF20V2,
frame2321Props: frame2321Data12V2,
frame2322Props: frame2322Data12V2,
frame2323Props: frame2323Data12V2,
frame2324Props: frame2324Data12V2,
watchVideo: "Watch video",
gradient1: "a2H0Qj9IrSw",
gradient2: "pkaNVfImVfE",
gradient3: "PR1-X_1KyLs",
gradient4: "HndQD14MuKY",
userGuide: "https://shop.resmed.com/medias/sys_master/images/images/hc2/ha4/8953495093278/airfit-airtouch-f20-user-guide-eur1-eng.pdf",
backPath: "/congratulationsV2F20"
};
const x12MaskSelectedQDataV2 = {
  title: "Quattro Air",
  airfitN20: "/img/quattro-air@3x.jpg",
  theAirfitN20Mask: <React.Fragment>Your Quattro™ Air is a full face mask with a silicone cushion for a comfortable fit and secure air pressure seal. It’s a safe choice if you have magnetic implants that may interfere with other types of CPAP masks.<br/><br/>To be successful in your therapy, you must be able to handle and care for your particular mask. Watch the following videos to learn how to adjust the headgear, manage air leaks, and perform routine daily and weekly cleanings for the Quattro Air.</React.Fragment>,
  maskFit: "Mask fit",
  cleaning: "Cleaning",
  faqs: "FAQs",
  spanText1: "If you have further questions, read the ",
  spanText2: "Quattro Air user guide",
  spanText3: ".",
  place: "Back",
  navbaractionProps: navbaractionDataV2,
  gradientCard1Props: gradientCard1DataV2,
  gradientCard2Props: gradientCard2DataV2,
  gradientCard3Props: gradientCard3DataQV2,
  gradientCard4Props: gradientCard4DataQV2,
  frame2321Props: frame2321Data12V2,
  frame2322Props: frame2322Data12V2,
  frame2323Props: frame2323Data12V2,
  frame2324Props: frame2324Data12V2,
  watchVideo: "Watch video",
  gradient1: "np5fdPsxAPc",
  gradient2: "3DUa56P6lf4",
  gradient3: "bqE9-2Ypme8",
  gradient4: "SKIP",
  gradient4Skip: true,
  gradientUseYoutube: true,
  userGuide: "https://document.resmed.com/documents/uk/628098_quattro-air-nv_user-guide_eur_eng.pdf",
  backPath: "/congratulationsV2Q"  
};
const x12MaskSelectedF30DataV2 = {
title: "AirFit F30",
airfitN20: "/img/AirFitF30.png",
theAirfitN20Mask: <React.Fragment>Your AirFit™ F30 is a full face mask with a silicone cushion that rests under your nose for more comfort and visibility. It’s a great choice if you tend to breathe through your mouth when you sleep but want a more open design. This mask is ideal for reading or watching tv in bed. <br/><br/>To be successful in your therapy, you must be able to handle and care for your particular mask. Watch the following videos to learn how to adjust the headgear, manage air leaks, and perform routine daily and weekly cleanings for the AirFit F30.</React.Fragment>,
maskFit: "Mask fit",
cleaning: "Cleaning",
faqs: "FAQs",
spanText1: "If you have further questions, read the ",
spanText2: "AirFit F30 user guide",
spanText3: ".",
place: "Back",
navbaractionProps: navbaractionDataV2,
gradientCard1Props: gradientCard1DataV2,
gradientCard2Props: gradientCard2DataV2,
gradientCard3Props: gradientCard3DataF30V2,
gradientCard4Props: gradientCard4DataF30V2,
frame2321Props: frame2321Data12V2,
frame2322Props: frame2322Data12V2,
frame2323Props: frame2323Data12V2,
frame2324Props: frame2324Data12V2,
watchVideo: "Watch video",
gradient1: "9jSAJFnerCY",
gradient2: "Q_xSjfGnAHk",
gradient3: "vyCXhZQ-Kj4",
gradient4: "xEBnkgJ_hdA",
gradient4Skip: false,
gradientUseYoutube: true,
userGuide: "https://document.resmed.com/documents/products/mask/airfit-f30/user-guide/airfit-f30_user-guide_row_mul.pdf",
backPath: "/congratulationsV2F30"
};
const x12MaskSelectedF30iDataV2 = {
title: "AirFit F30i",
airfitN20: "/img/AirFitF30i.png",
theAirfitN20Mask: <React.Fragment>Your AirFit™ F30i is a full face mask with a silicone cushion and an air tubing connection at the top of your head for more comfort and freedom. It’s a great choice if you sleep in different positions and tend to breathe through your mouth while asleep.<br/><br/>To be successful in your therapy, you must be able to handle and care for your particular mask. Watch the following videos to learn how to adjust the headgear, manage air leaks, and perform routine daily and weekly cleanings for the AirFit F30i.</React.Fragment>,
maskFit: "Mask fit",
cleaning: "Cleaning",
faqs: "FAQs",
spanText1: "If you have further questions, read the ",
spanText2: "AirFit F30i user guide",
spanText3: ".",
place: "Back",
navbaractionProps: navbaractionDataV2,
gradientCard1Props: gradientCard1DataV2,
gradientCard2Props: gradientCard2DataV2,
gradientCard3Props: gradientCard3DataF30iV2,
gradientCard4Props: gradientCard4DataF30iV2,
frame2321Props: frame2321Data12V2,
frame2322Props: frame2322Data12V2,
frame2323Props: frame2323Data12V2,
frame2324Props: frame2324Data12V2,
watchVideo: "Watch video",
gradient1: "8nfVGMRIFZU",
gradient2: "n83IJnP1wyY",
gradient3: "nmhYTlAO_mE",
gradient4: "soZ7A0bBxTI",
gradient4Skip: false,
gradientUseYoutube: true,
userGuide: "https://document.resmed.com/documents/products/mask/airfit-f30i/user-guide/airfit-f30i_user-guide_eur1_mul.pdf",
backPath: "/congratulationsV2F30i"
};

const x12MaskSelectedP10DataV2 = {
title: "AirFit P10",
airfitN20: "/img/AirFitP10.png",
theAirfitN20Mask: <React.Fragment>Your AirFit™ P10 is a nasal pillow mask with a silicone cushion for a comfortable fit. It’s a great choice if you move around in your sleep and want a more open design. This mask is ideal for reading or watching tv in bed.<br/><br/>To be successful in your therapy, you must be able to handle and care for your particular mask. Watch the following videos to learn how to adjust the headgear, manage air leaks, and perform routine daily and weekly cleanings for the AirFit P10.<br/><br/><b>Note:</b> Your mask’s air tubing may look different than those seen in the videos. This does not affect the processes described.</React.Fragment>,
maskFit: "Mask fit",
cleaning: "Cleaning",
faqs: "FAQs",
spanText1: "If you have further questions, read the ",
spanText2: "AirFit P10 user guide",
spanText3: ".",
place: "Back",
navbaractionProps: navbaractionDataV2,
gradientCard1Props: gradientCard1DataV2,
gradientCard2Props: gradientCard2DataV2,
gradientCard3Props: gradientCard3DataP10V2,
gradientCard4Props: gradientCard4DataP10V2,
frame2321Props: frame2321Data12V2,
frame2322Props: frame2322Data12V2,
frame2323Props: frame2323Data12V2,
frame2324Props: frame2324Data12V2,
watchVideo: "Watch video",
gradient1: "3xBKrBtVXeA",
gradient2: "stmx9yw2fwk",
gradient3: "tTJ2c3bVCj0",
gradient4: "NG3lpiWh84Q",
gradient4Skip: false,
gradientUseYoutube: true,
userGuide: "https://document.resmed.com/documents/products/mask/airfit-p10/user-guide/airfit-p10_user-guide_eur1_mul.pdf",
backPath: "/congratulationsV2P10"
};

// x13

const frame1981DataV2 = {
learnMoreAboutYourMask: "Learn more about your mask",
onceYourEquipment: "Select the mask included in your CPAP equipment shipment. You can refer to the information in your text messages. Learn about fitting, cleaning and managing leaks for your mask and access the user guide:",
};


const frame1982DataV2 = {
learnMoreAboutYourMask: "Getting started with myAir",
onceYourEquipment: <React.Fragment>As you set up your new CPAP equipment, don’t forget to install the myAir™ app. After installing the app, register for an account using the serial number on your AirSense™ 10.<br /><br />Each night that you use your CPAP machine, your sleep data automatically syncs from your equipment to your account after you wake up. The myAir app uses that information to track your progress and provide timely feedback, helping you get the most out of your CPAP treatment.<br /><br />Check the app each day to see your sleep score and coaching advice.</React.Fragment>,
};

const x13ExpandedDataV2 = {
title: "Your CPAP equipment arrives soon!",
inThisIssueWeve: "In this issue, we’ve included some more tips for starting and acclimating to CPAP treatment.",
inThisIssue: "In this issue:",
learnMoreAboutYourMask: "Learn more about your mask",
gettingStartedWithMyair: "Getting started with myAir",
sleepingWithACpapMachine1: "Sleeping with a CPAP machine",
tipsForBuildingYo: "Tips for building your confidence in CPAP treatment",
airfitN201: "AirFit N20",
airfitN202: "/img/airfit-n20@2x.png",
airtouchN20Outsidetable60591200X800: "/img/airtouch-n20-outsidetable-6059-1200x800-5b2df79-1@2x copy.png",
sleepingWithACpapMachine2: "Sleeping with a CPAP machine",
adaptingToCpapThe: <React.Fragment>Adapting to CPAP treatment isn’t always easy. Many people with sleep apnoea will tell you they hated their CPAP mask the first time they tried it. You may feel hesitant about wearing the mask to sleep or fear you’ll never find a comfortable sleeping position.<br /><br />The good news: You’re not alone.<br /><br />The better news: There are plenty of tips and tricks to make it easier to adjust to your sleep apnoea treatment.</React.Fragment>,
read7TipsForAdju: "Read 7 tips for adjusting to CPAP treatment",
readLess: "Read less",
article: "Article",
dtc_Maskrefresh2022_2228_Resmed_01_: "/img/dtc-maskrefresh2022-2228-resmed-01-0806-1@2x.png",
tipsForBuildingConfidence: "Tips for building confidence",
itMayFeelAwkward: "It may feel awkward at first, but with perseverance and utilising the tips you’ve learned, you’ll soon be getting great sleep with your CPAP machine. Listen to Pamela explain the challenges she faced when she started CPAP treatment and how building a routine with her equipment helped build her confidence.",
video: "Video",
mostPeopleWhoUse: <React.Fragment>Most people who use CPAP treatment agree that it takes some getting used to. But they also say it’s worth it. Like anything new, you’ve got to give it time.<br /><br />You’ve got this.</React.Fragment>,
sleepWellTheMyairTeam: <React.Fragment>Sleep well!<br />The myAir Team</React.Fragment>,
sources: "(sources)",
frame1981Props: frame1981DataV2,
frame2271Props: frame2271DataV2,
frame2272Props: frame2272DataV2,
frame1982Props: frame1982DataV2,
buttonProps: buttonDataV2,
patientStory: "Patient story",
readLess2: "Read less",
};