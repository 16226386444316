import React, { useEffect } from "react";
import Navbaraction from "../Navbaraction";
import GradientCard from "../GradientCard";
import Frame232 from "../Frame232";
import "./X12MaskSelected.css";
import { useHistory } from 'react-router-dom'

function X12MaskSelected(props) {
  useEffect(() => window.scrollTo(0, 0), []);

  const history = useHistory();
  const goBack = () => history.replace('/arrives');

  const {
    title,
    airfitN20,
    theAirfitN20Mask,
    maskFit,
    cleaning,
    faqs,
    spanText1,
    spanText2,
    spanText3,
    place,
    navbaractionProps,
    gradientCard1Props,
    gradientCard2Props,
    gradientCard3Props,
    gradientCard4Props,
    frame2321Props,
    frame2322Props,
    frame2323Props,
    frame2324Props,
    watchVideo,
    gradient1,
    gradient2,
    gradient3,
    gradient4,
    userGuide,
    gradient4Skip,
    gradientUseYoutube
  } = props;

  return (
    <div className="x12-selected container-center-horizontal">
      <div className="x1-2-mask-selected-n20 screen">
        <div className="navbarback" onClick={goBack}>
          <div className="chevron over ride">
            <img className="vector-4" src={require("../../static/img/vector-4.svg")} alt="Vector 4" />
          </div>
          <Navbaraction>{navbaractionProps.children}</Navbaraction>
        </div>
        <h1 className="title">{title}</h1>
        <img className="air-fit-n20" src={airfitN20} alt="AirFit N20" />
        <p className="the-air-fit-n20-mask sfprotext-regular-normal-log-cabin-14px">{theAirfitN20Mask}</p>
        <div className="mask-fit sfprodisplay-normal-log-cabin-20px">{maskFit}</div>
        { gradientUseYoutube &&
          <div>
            <GradientCard button={watchVideo} videoId={gradient1} leftAdjust="-right36" textProps={gradientCard1Props.textProps}/>
            <GradientCard button={watchVideo} videoId={gradient2} leftAdjust="-right36" className={gradientCard2Props.className} textProps={gradientCard2Props.textProps} />
            <div className="cleaning sfprodisplay-normal-log-cabin-20px">{cleaning}</div>
            <GradientCard button={watchVideo} videoId={gradient3 } leftAdjust="-right36" className={gradientCard3Props.className} textProps={gradientCard3Props.textProps} />
          </div>
        }
        { !gradientUseYoutube &&
          <div>
            <GradientCard button={watchVideo} videoId={gradient1} leftAdjust="-right36" textProps={gradientCard1Props.textProps} />
            <GradientCard button={watchVideo} videoId={gradient2} leftAdjust="-right36" className={gradientCard2Props.className} textProps={gradientCard2Props.textProps} />
            <div className="cleaning sfprodisplay-normal-log-cabin-20px">{cleaning}</div>
            <GradientCard button={watchVideo} videoId={gradient3} leftAdjust="-right36" className={gradientCard3Props.className} textProps={gradientCard3Props.textProps} />
          </div>
        }
        { !gradient4Skip && 
          <GradientCard button={watchVideo} videoId={gradient4} leftAdjust="-right36" className={gradientCard4Props.className} textProps={gradientCard4Props.textProps} />
        }
        <div className="frame-247">
          <div className="fa-qs sfprodisplay-normal-log-cabin-20px">{faqs}</div>
          <Frame232 faqQuestion={frame2321Props.faqQuestion} faqContents={["Wash your mask in clean warm (around 30°C) water with mild liquid detergent, making sure you rinse all components thoroughly before allowing them to dry naturally. Make sure you do not soak the mask. ", <br/>, "If you’re running short of time, our mask wipes are a fast and easy cleaning option that can be used on any mask. Simply wipe out the mask and leave it to dry naturally. For step-by-step instructions on cleaning your mask, see the user guide for your mask."]}  className="frame-2-3"/>
          <Frame232 faqQuestion={frame2322Props.faqQuestion}  className="frame-2-3" faqContents={["Avoid soap with conditioners or moisturisers. Do not use bleach, alcohol, or cleaning solutions containing alcohol. "]}/>
          <Frame232 faqQuestion={frame2323Props.faqQuestion}  className="frame-2-3" faqContents={["It is recommended that every 6 months you check your CPAP mask headgear and chin straps.", <br />, "If they’ve lost their elasticity, you may tend to over-tighten the mask, resulting in some discomfort. Replace any part that is stretched and is no longer providing a good fit. For more information about replacing or cleaning your mask headgear, see the user guide for your mask or talk to your equipment provider."]}/>
          <Frame232 faqQuestion={frame2324Props.faqQuestion}  className="frame-2-3" faqContents={["It is recommended that every 6 months you check the filter at the back of your CPAP machine. For more information on how and when to replace your filter, see the user guide for your machine.", <br/>, "A build-up of dust can impede the effectiveness of your treatment. If you live in a dusty area, or close to the sea, you need to replace the filter more frequently."]}/>
        </div>
        <p className="if-you-have-further">
          <span className="sfprotext-regular-normal-log-cabin-14px">{spanText1}</span>
          <a
            href={userGuide}
            target="_blank"
          >
            <span className="span1">{spanText2}</span>
          </a>
          <span className="sfprotext-regular-normal-log-cabin-14px">{spanText3}</span>
        </p>
        <div className="button" onClick={goBack}>
          <div className="place">{place}</div>
        </div>
      </div>
    </div>
  );
}

export default X12MaskSelected;
