import React, { useRef, useEffect, useState } from "react";
import "./LearnMore.css";

export function useComponentVisible(initialIsVisible) {
  
}

function LearnMore(props) {
  return(
  <div className="button-1">
    <div className="button-2">Learn more</div>
    <img className="start-stop" src={require('../../static/img/ArrowRight.svg')} alt="Learn More" />
  </div>
  )
}

export default LearnMore;
