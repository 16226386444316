import React from "react";
import "./IconspersonOutline24px.css";

function IconspersonOutline24px() {
  return (
    <div className="iconsperson_outline_24px">
      <img className="icon" src="/img/icon-1.svg" alt="icon" />
    </div>
  );
}

export default IconspersonOutline24px;
