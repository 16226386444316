import React, { useState, useEffect } from "react";
import "./Frame232.css";

function Frame232(props) {
  const { faqQuestion, className, faqContents, useOL } = props;

  const [readMore, setReadMore] = useState(false);

  useEffect(() => {
  }, [readMore]);

  const changeReadMore = () => {
    setReadMore(!readMore);
  }

  return (
    <div className={`frame-2-1 ${className || ""}`} onClick={changeReadMore}>
      <div className="frame-233">
        <div className="faq-question sfprotext-normal-cape-cod-17px">{faqQuestion}</div>
        <img className="chevron-2" src="/img/chevron-2.svg" alt="Chevron" />
      </div>
      { readMore && <div className="read-more-text" style={{ whiteSpace: "pre-line" }}>
        {!useOL && faqContents}
        {useOL &&
        <div>
        Ultimately your healthcare provider decides the process of your therapy but from ResMed there are four key steps:
          <ul  style={{'listStyle': 'decimal'}}>
            <li  style={{'listStyle': 'decimal', 'display': 'list-item'}}>You are first contacted to say that you were referred for sleep apnoea treatment. After this you are put on a waiting list. You do not need to do anything until we contact you again.</li>
            <li  style={{'listStyle': 'decimal', 'display': 'list-item'}}>Once we are ready to start your treatment, you are asked some questions to determine what kind of mask works best for you.</li>
            <li  style={{'listStyle': 'decimal', 'display': 'list-item'}}>Next, your CPAP device and mask are delivered to your home.</li>
            <li  style={{'listStyle': 'decimal', 'display': 'list-item'}}>Once you have received your equipment, you are asked to download ResMed’s myAir™ app to support and track your treatment.</li>
          </ul>
          </div>
        }
      </div>
      }
    </div>
  );
}

export default Frame232;
